import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AnalyticsService } from '../services/analytics.service';
import { CreditTypes, CreditsData } from '../models/credits.interface';
import { CreditsValues } from './creditsValues';


@Component({
  selector: 'app-creditos',
  templateUrl: './creditos.component.html',
  styleUrls: ['./creditos.component.scss']
})
export class CreditosComponent implements OnInit {

  constructor(private analyticsService: AnalyticsService) {
  }

  public CreditsTypes: CreditsData[] = []
  public creditProgramSelected: number = 0

  ngOnInit(): void {
    // Analytics
    this.analyticsService.logEventScreenView(
      'Credits',
      CreditosComponent.name);

    this.CreditsTypes = CreditsValues;

    this.instituteData = this.CreditsTypes[0]
    this.programData = this.instituteData.creditPrograms[this.creditProgramSelected]
  }

  instituteSelectedName: string | undefined
  instituteData: CreditsData | undefined;
  programData: CreditTypes | undefined

  @ViewChild('focus', { read: ElementRef }) focus!: ElementRef;

  scrollDown(): void {
    // console.warn('scrollUp ...');
    setTimeout(() => this.focus.nativeElement.scrollIntoView({ behavior: 'smooth', block: "end", inline: "nearest" }));
  }


  @ViewChild('init', { read: ElementRef }) init!: ElementRef;

  scrollUp(): void {
    // console.warn('scrollUp ...');
    setTimeout(() => this.init.nativeElement.scrollIntoView({ behavior: 'smooth', block: "start", inline: "nearest" }));
  }




  changeInstitute(value: string): void {
    this.instituteSelectedName = value
    // console.log("Selected institute", this.instituteSelectedName) 
    this.instituteData = this.getInstituteData()
    // console.log("Institute Data", this.instituteData)
    this.programData = this.instituteData?.creditPrograms[0]
  }

  getInstituteData(): CreditsData | undefined {
    const instituteSelected = this.instituteSelectedName;

    if (!instituteSelected) {
      return undefined;
    }
    return this.CreditsTypes.filter((institute) => institute.institute === instituteSelected)[0]
  }

  changeCreditProgram(value: number): void {
    this.creditProgramSelected = value
    // console.log("Valorr de index", value)
    // console.log("CreditProgramSelected", this.creditProgramSelected)
    this.programData = this.getCreditProgram()
    // console.log("ProgramData", this.programData)

  }
  getCreditProgram(): CreditTypes | undefined {
    if (this.instituteData) {
      return this.instituteData.creditPrograms[this.creditProgramSelected]
    }
    return undefined
  }

  getSvg(title: string) {
    switch (title) {
      case "Facebook":
        return "../../assets/svg/logos/facebook-app-icon.svg";
      case "Instagram":
        return "../../assets/svg/logos/ig-instagram-icon.svg";
      case "X":
        return "../../assets/svg/logos/x-twitter-icon.svg";
      case "Whatsapp":
        return "../../assets/svg/logos/wa-whatsapp-icon.svg";
      default:
        return "";
    }
  }

}
