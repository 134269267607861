<header class="stickytop bg-light">
    <div class="d-grid align-items-center pb-0 mb-0 d-lg-none">
        <a mat-flat-button class="active no-rounded p-1" color="primary" routerLink="/donaciones">
            <span>QUIERO DONAR</span></a>
    </div>
    <nav class="py-1 py-md-2 w-100 shadow-sm">
        <div class="d-flex justify-content-between alignBaseline">
            <app-logo size="130px" class="ms-1 mt-1"></app-logo>
            <ul class="d-none links d-lg-flex gap-3 justify-content-start">
                <li class="">
                    <a mat-button routerLinkActive="activebutton" routerLink="/buscador">Buscador</a>
                </li>
                <li class="">
                    <a mat-button routerLinkActive="activebutton" routerLink="/test-vocacional">Test vocacional</a>
                </li>
                <li class="">
                    <a mat-button routerLinkActive="activebutton" routerLink="/comparador">Comparador</a>
                </li>
                <li class="">
                    <a mat-button routerLinkActive="activebutton" routerLink="/creditos">Créditos</a>
                </li>
                <!-- <li class="">
                    <a mat-button routerLinkActive="activebutton" routerLink="/creditos">Créditos</a>
                </li>
                <li class="">
                    <a mat-button routerLinkActive="activebutton" routerLink="/servicios">Servicios</a>
                </li> -->
                <!-- <li class="">
                    <a mat-button routerLinkActive="activebutton" routerLink="/nosotros">Nosotros</a>
                </li> -->
                <!-- <li class="">
                    <a mat-button routerLinkActive="activebutton" routerLink="/donaciones">Donaciones</a>
                </li> -->
                <button mat-button [matMenuTriggerFor]="belowMenu">
                    Más <mat-icon>keyboard_arrow_down</mat-icon></button>
                <mat-menu #belowMenu="matMenu" yPosition="below">
                    <button mat-menu-item routerLinkActive="activebutton2" routerLink="/servicios">Servicios</button>
                    <button mat-menu-item routerLinkActive="activebutton2" routerLink="/nosotros">Nosotros</button>
                    <button mat-menu-item routerLinkActive="activebutton2"
                        routerLink="/contactanos">Contáctanos</button>
                </mat-menu>

            </ul>
            <ul class="d-none d-lg-flex me-3 align-items-center align-content-center">
                <!-- <div class="d-none d-lg-flex"> -->
                <li>
                    <button mat-button (click)="openDialog()">
                        <mat-icon [matBadge]="comparisonService.getLength()"
                            [matBadgeHidden]="!(comparisonService.getLength()>0)" matBadgeSize="small"
                            matBadgeColor="primary">compare_arrows</mat-icon>
                    </button>
                </li>
                <li>
                    <button mat-button routerLink="/favoritos">
                        <mat-icon [matBadge]="favoriteService.getLengthFavorites()"
                            [matBadgeHidden]="!(favoriteService.getLengthFavorites()>0)" matBadgeSize="small"
                            matBadgeColor="primary">favorite_border</mat-icon>
                    </button>
                </li>
                <li *ngIf="userAccessGuard.validateCanActivate()" class="pe-1">
                    <button mat-icon-button class="profileIcon" [matMenuTriggerFor]="menu"
                        aria-label="icon-button with a menu">
                        <!-- <mat-icon *ngIf="!profilePhotoUrl">account_circle</mat-icon> -->
                        <div class="rounded-circle h-100 w-100">
                            <img class="img-fluid fit-cover rounded-circle icon_photo" slot="start"
                                referrerpolicy="no-referrer" [src]="userService.PROFILE_PHOTO_URL??DefaultProfilePhoto"
                                alt="Profile Photo">
                        </div>
                    </button>
                </li>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item routerLink="/perfil">
                        <mat-icon>account_circle</mat-icon>
                        <span>Perfil</span>
                    </button>
                    <!-- <button mat-menu-item disabled>
                        <mat-icon>favorite</mat-icon>
                        <span>Favoritos</span>
                    </button> -->
                    <button mat-menu-item (click)="onLogout()">
                        <mat-icon>logout</mat-icon>
                        <span>Cerrar sesión</span>
                    </button>
                </mat-menu>
                <!-- <ul class="d-flex me-3 align-items-center align-content-center"
                *ngIf="!userAccessGuard.validateCanActivate()"> -->
                <li class="list-inline-item align-items-center" *ngIf="!userAccessGuard.validateCanActivate()">
                    <a mat-stroked-button class="active" routerLink="/login">Inicia sesión</a>
                </li>
                <li class="list-inline-item align-items-center">
                    <a mat-flat-button class="active" color="primary" routerLink="/donaciones">
                        <span>QUIERO DONAR</span></a>
                </li>
            </ul>
            <!-- </ul> -->
            <!-- </div> -->
            <div class="d-lg-none me-2 clearfix">
                <button mat-icon-button routerLink="/buscador" *ngIf="(currentUrl!='/buscador')" class="float-right">
                    <mat-icon>search</mat-icon>
                    <span></span>
                </button>
                <button mat-icon-button (click)="openDialog()" class="float-right">
                    <mat-icon [matBadge]="comparisonService.getLength()"
                        [matBadgeHidden]="!(comparisonService.getLength()>0)" matBadgeSize="small"
                        matBadgeColor="primary">compare_arrows</mat-icon>
                </button>
                <button mat-button routerLink="/favoritos">
                    <mat-icon [matBadge]="favoriteService.getLengthFavorites()"
                        [matBadgeHidden]="!(favoriteService.getLengthFavorites()>0)" matBadgeSize="small"
                        matBadgeColor="primary">favorite_border</mat-icon>
                </button>
                <button mat-icon-button (click)="open()" type="button" class="float-right">
                    <mat-icon>menu</mat-icon>
                </button>
            </div>
        </div>
    </nav>
</header>