import { CreditsData } from '../models/credits.interface';

export const CreditsValues: CreditsData[] = [
    {
        institute: "U. de los Andes",
        link: "https://apoyofinanciero.uniandes.edu.co/",
        image: "../../assets/svg/uniandes.svg",
        principalHeadquarter: {
            city: "Bogotá D.C.",
            region: "Cundinamarca",
            address: "Calle 18A No 0-33 Este, Bloque E"
        },
        callCenterHours: {
            startDay: "Lunes",
            endDay: "viernes",
            schedule: "7:00 a.m. a 7:00 p.m.",
            // weekendDay: "Sábado",
            // weekendStartHour: "8:00 a.m.",
            // weekendEndHour: "4:00 p.m"
        },
        numberChannels: [
            {
                city: "",
                diallingCode: "(+57)",
                phoneNumber: "(601) 339 4949, Ext 3686, 3108"
            },
            {
                city: "",
                diallingCode: "(+57)",
                phoneNumber: "(601) 339 4999, Ext 3686, 5455"
            }
        ],
        socialMedia: [
            {
                title: "Whatsapp",
                link: "https://api.whatsapp.com/send?phone=573059399364&text=%C2%A1Hola%21%20Estoy%20interesado%20en%20obtener%20m%C3%A1s%20informaci%C3%B3n%20de%20m%C3%A9todos%20de%20financiancion%20en%20Los%20Andes%20%F0%9F%A4%93"
            }
        ],
        creditPrograms: [
            {
                title: "Línea de crédito de pregrado o posgrado / Becas",
                link: "https://programas.uniandes.edu.co/apoyo-financiero",
                benefits: [
                    "Te ayudamos a continuar tu formación académica y a acceder a un posgrado en Uniandes a través de diferentes tipos de apoyos financieros como becas, préstamos, auxilios, descuentos y más. También contamos con convenios con instituciones como Icetex y la fundación CEIBA. Conoce todas las formas en las que podemos apoyarte para que hagas parte de una de las mejores instituciones educativas de Colombia y Latinoamérica."
                ],
                // requiremets: [
                // ],
                // interestRate: "Entre 13.12% al 23.30%",
                // interestType: "E.A."
            },
        ]
    }, {
        institute: "U. de La Sabana",
        link: " https://www.unisabana.edu.co/financiacion/",
        image: "../../assets/images/unisabana-logo.png",
        principalHeadquarter: {
            city: "Chía",
            region: "Cundinamarca",
            address: "Campus del Puente del Común, Km. 7, Autopista Norte de Bogotá"
        },
        callCenterHours: {
            startDay: "Lunes",
            endDay: "viernes",
            schedule: "8:00 a.m. a 12:00 p.m. y 2:00 p.m. a 5:00 p.m.",
            // weekendDay: "Sábado",
            // weekendStartHour: "8:00 a.m.",
            // weekendEndHour: "4:00 p.m"
        },
        numberChannels: [
            {
                city: "",
                diallingCode: "(+57)",
                phoneNumber: "(601) 8615555"
            },
            {
                // email: "solicitud.creditos@unisabana.edu.co"
                city: "",
                diallingCode: "",
                phoneNumber: "solicitud.creditos@unisabana.edu.co"
            }
        ],
        socialMedia: [
            {
                title: "Whatsapp",
                link: "https://api.whatsapp.com/send/?phone=573205164838&text=Hola%2C+Universidad+de+La+Sabana.+Necesito+ayuda&type=phone_number&app_absent=0"
            }
        ],
        creditPrograms: [
            {
                title: "¡Financia tu matrícula ya!",
                link: "https://www.unisabana.edu.co/financiacion/",
                benefits: [
                    "La Universidad de La Sabana ofrece a sus estudiantes una amplia gama de opciones de financiación directa, a corto y mediano plazo, y a través de Entidades Financieras con condiciones de crédito preferenciales."
                ],
                // requiremets: [
                // ],
                // interestRate: "Entre 13.12% al 23.30%",
                // interestType: "E.A."
            },
        ]
    },
    {
        institute: "ICETEX",
        link: "https://web.icetex.gov.co/index.html",
        image: "../../assets/svg/icetex.svg",
        principalHeadquarter: {
            city: "Bogotá D.C.",
            region: "Cundinamarca",
            address: "Carrera 3 No. 18-32"
        },
        callCenterHours: {
            startDay: "Lunes",
            endDay: "viernes",
            schedule: "7:00 a.m. a 5:30 p.m.",
            weekendDay: "Sábado",
            weekendStartHour: "8:00 a.m.",
            weekendEndHour: "4:00 p.m"
        },
        numberChannels: [
            {
                city: "Call center",
                diallingCode: "(+57)",
                phoneNumber: "3336025656"
            },
            {
                city: "Línea nacional (gratuita)",
                diallingCode: "",
                phoneNumber: "01 8000 916 821"
            }
        ],
        socialMedia: [
            {
                title: "Instagram",
                link: "https://www.instagram.com/icetex_colombia/"
            },
            {
                title: "Facebook",
                link: "https://www.facebook.com/icetexcolombia"
            }
        ],
        creditPrograms: [
            {
                title: "Línea de crédito de pregrado",
                link: "https://web.icetex.gov.co/creditos",
                benefits: [
                    "Las líneas de pregrado son créditos que aplican para carreras técnicas, tecnológicas o profesionales para estudios en Instituciones de Educación Superior (universidades, Instituciones Técnicas Profesionales y Escuelas Tecnológicas)."
                    // "Línea largo plazo 0% : Con tasa del  IPC que equivale al 1,03% mes vencido (13,12% efectivo anual) *IPC Tasa subsidiada para quienes cumplan con SISBEN IV en los siguientes grupos de clasificación: A, B hasta C7.",
                    // "Línea mediano plazo 30% : Con tasa del IPC + 9 que equivale al 1,76% mes vencido (23,30% efectivo anual).",
                    // "Línea corto plazo 100% : Con tasa del  IPC + 7 puntos = 21.04% efectivo anual",
                    // "Línea territorial : Con tasa del  IPC + 0 (13,12% efectivo anual) *IPC Tasa subsidiada para quienes cumplan con SISBEN IV en los siguientes grupos de clasificación: A, B hasta C7.",
                    // "Entre otras."
                ],
                requiremets: [
                    {
                        requirement: [
                            "Llenar el formulario de solicitud de acuerdo al programa seleccionado",
                            "Número de registro ICFES (SNP)"
                        ]
                    }
                ],
                // interestRate: "Entre 13.12% al 23.30%",
                // interestType: "E.A."
            },
            {
                title: "Línea de Posgrado país o exterior",
                link: "https://web.icetex.gov.co/creditos",
                benefits: [
                    "Las líneas de crédito de posgrado financian estudios en diferentes niveles como especialización, maestría o doctorado en el país y en el exterior."
                    // "Posgrado país : Con tasa del IPC + 8%, que equivale al 1,68% mes vencido (22,17% efectivo anual)",
                    // "Posgrado país medicina : Con tasa del IPC + 8%, que equivale al 1,68% mes vencido (22,17% efectivo anual)",
                    // "Exterior para sostenimiento : Con tasa del IPC + 8%, que equivale al 1,68% mes vencido (22,17% efectivo anual)",
                    // "Exterior U$25.000 : Con tasa del  IPC + 8%, que equivale al 1,68% mes vencido (22,17% efectivo anual)",
                    // "Entre otras."
                ],
                requiremets: [
                    {
                        requirement: [
                            "Estudio de antecedentes crediticios sin deudor solidario",
                            "Realizar pago  de los resultados",
                            "Seguir indicaciones según la página principal"
                        ]
                    }
                ],
                // interestRate: "IPC + % de acuerdo al plan seleccionado",
                // interestType: "E.A."
            },
            {
                title: "Línea de crédito de idiomas, pasantías o investigación",
                link: "https://web.icetex.gov.co/creditos",
                benefits: [
                    "Son créditos educativos dirigidos a personas que desean estudiar posgrado de formación en lengua extranjera fuera del país, optar por opción en pasantías, o programas de investigación e intercambio educativo."
                    // "Pasantías, programas e investigación : Con tasa delIPC +8 que equivale al 1,68% mes vencido (22,17% efectivo anual).",
                    // "Financia intercambios o pasantías, en convenios entre universidades del país y del exterior.",
                    // "Perfeccionamiento de idiomas : Con tasa del IPC +8 que equivale al 1,68% mes vencido (22,17% efectivo anual).",
                    // "Pueden aplicar técnicos, tecnólogos, profesionales o estudiantes de pregrado con 5 semestres cursados y aprobados en una institución de Educación Superior con reconocimiento del Ministerio de Educación Nacional."
                ],
                requiremets: [
                    {
                        requirement: [
                            "Estudio de antecedentes crediticios",
                            "Realizar pago  de los resultados",
                            "Seguir indicaciones según la página principal"
                        ]
                    }
                ],
                // interestRate: "IPC + % de acuerdo al plan seleccionado",
                // interestType: "E.A."
            },
        ]
    },
    {
        institute: "Bancolombia - Sufi",
        link: "https://sufi.grupobancolombia.com/sufi/creditos/campus-sufi/credito-educativo/productos-seguros",
        image: "../../assets/svg/bancolombia.svg",
        principalHeadquarter: {
            city: "Medellín",
            region: "Colombia",
            address: "Carrera 46 # 27 95, Oficina 222"
        },
        callCenterHours: {
            startDay: "Lunes",
            endDay: "viernes",
            schedule: "8:00 a.m. a 12:00 p.m. y de 1:30 p.m. a 4:30: pm ",

        },
        numberChannels: [
            {
                city: "Bogotá D.C.",
                diallingCode: "(+57)",
                phoneNumber: "601 4446600"
            },
            {
                city: "Medellín",
                diallingCode: "(+57)",
                phoneNumber: "604 5107880 "
            },
            {
                city: "Cali",
                diallingCode: "(+57)",
                phoneNumber: "602 5540585"
            },
            {
                city: "Línea nacional (gratuita)",
                diallingCode: "",
                phoneNumber: "01 8000 517 834"
            }
        ],
        socialMedia: [
            {
                title: "Instagram",
                link: "https://www.instagram.com/consufi/"
            },
            {
                title: "Facebook",
                link: "https://www.facebook.com/sufitepresta/"
            },
            {
                title: "Whatsapp",
                link: "https://api.whatsapp.com/send/?phone=573187528722"
            }
        ],
        creditPrograms: [
            {
                title: "Crédito educativo Sufi a corto plazo",
                link: "https://sufi.grupobancolombia.com/sufi/creditos/campus-sufi/credito-educativo/corto-plazo",
                benefits: [
                    "Este crédito rotativo podrá cubrir hasta el 100% del valor de tu matrícula en Colombia o en el país que elijas. Además, podrás reutilizarlo a medida que vayas liberando cupo con el pago de las cuotas para continuar con tu formación, es decir, con él podrías costear uno o varios periodos académicos hasta la totalidad del programa formativo que curses.",
                    "Plazo de pago: de 6 y 12 meses.",
                    "Capital que ofrece: desde 700 mil pesos hasta 25 S.M.M.L.V. (Salarios Mínimos Mensuales Legales Vigentes).",
                    "Renovación sujeta a políticas de crédito de la Entidad.",
                    "Respuesta en minutos y sin documentos *sujeta a políticas de crédito de la Entidad.",
                    "Desembolso en línea *En algunos casos podrá requerirse estudio de crédito adicional.",
                    "Vigencia créditos aprobados: hasta 15 días calendario para aceptar la oferta y después de aceptada la oferta hasta 75 días calendario para desembolsar."
                ],
                interestRate: "Desde* 1.71% M.V. equivalente a 22.56% ",
                interestType: "E.A."
            },
            {
                title: "Crédito libre educativo Sufi",
                link: "https://sufi.grupobancolombia.com/sufi/creditos/campus-sufi/credito-educativo/libre-educativo",
                benefits: [
                    "Plazo de pago: 24, 36 y 48 meses.",
                    "Capital que ofrece: desde 700 mil pesos hasta 25 S.M.M.L.V. (Salarios Mínimos Mensuales Legales Vigentes).",
                    "Renovación sujeta a políticas de crédito de la Entidad.",
                    "Vigencia créditos aprobados: hasta 15 días calendario para aceptar la oferta y después de aceptada la oferta hasta 75 días calendario para desembolsar."
                ],
                interestRate: "Desde* 1.71% M.V. equivalente a 22.56% ",
                interestType: "E.A."
            },
            {
                title: "Crédito educativo Sufi a largo plazo",
                link: "https://sufi.grupobancolombia.com/sufi/creditos/campus-sufi/credito-educativo/largo-plazo",
                benefits: [
                    "Con este crédito* podrás pagar un pregrado, tus estudios en el extranjero o un posgrado. Te permitirá hacer una utilización por cada periodo académico, así lograrás cubrir hasta el 100% del programa que hayas elegido",
                    "Plazo de pago: cada utilización se puede diferir a 60, 72 u 84 meses.",
                    "Capital que ofrece: desde $5.000.000 hasta el valor total de tu programa académico, según tu capacidad de endeudamiento.",
                    "Renovación sujeta a políticas de crédito de la Entidad.",
                    "Vigencia créditos aprobados: hasta 90 días calendario para desembolsar.",
                ],
                interestRate: "Desde* 1.71% M.V. equivalente a 22.56% ",
                interestType: "E.A."
            },
        ],
        instituteNote: "El Banco determinará al momento de la aprobación, si es necesario que tu crédito esté respaldado por una fianza a favor del Banco otorgada por un Fondo de Garantías. Además de financiarte el valor del bien o servicio que deseas obtener, El Banco también te financia la comisión que causará dicha garantía a favor del Fondo de Garantías, difiriendo su valor en las mismas cuotas mensuales, de acuerdo con el mismo plazo establecido para el pago del crédito y con la misma tasa acordada y se encargará de entregar el dinero a dicho Fondo al inicio de cada utilización del cupo, situación ésta que es de tu conocimiento y aceptación."
    },
    {
        institute: "Serfinanza",
        link: "https://bancoserfinanza.com/personas/credito-educativo/",
        image: "../../assets/svg/serfinanza.svg",
        principalHeadquarter: {
            city: "Barranquilla",
            region: "Colombia",
            address: "Calle 72 # 54 - 67"
        },
        callCenterHours: {
            startDay: "Lunes",
            endDay: "sábado",
            schedule: "7:00 a.m. a 10:00 p.m.",
            weekendDay: "Domingos y festivos",
            weekendStartHour: "8:00 a.m.",
            weekendEndHour: "10:00 p.m."

        },
        numberChannels: [
            {
                city: "Barranquilla",
                diallingCode: "(+57)",
                phoneNumber: "605 3361990  "
            },
            {
                city: "Bogotá D.C.",
                diallingCode: "(+57)",
                phoneNumber: "601 7436978"
            },
            {
                city: "Medellín",
                diallingCode: "(+57)",
                phoneNumber: "604 6040553"
            },
            {
                city: "Línea nacional (gratuita)",
                diallingCode: "",
                phoneNumber: "01 8000 510 513"
            }
        ],
        socialMedia: [
            {
                title: "Instagram",
                link: "https://www.instagram.com/bancoserfinanza/"
            },
            {
                title: "Facebook",
                link: "https://www.facebook.com/BancoSerfinanza/"
            },

        ],
        creditPrograms: [
            {
                title: "Crédito educativo",
                link: "https://bancoserfinanza.com/personas/credito-educativo/",
                benefits: [
                    "Convenios con Universidades y Centros Educativos para estudiantes que tomen crédito con el Banco.",
                    "Financia hasta el 100% del costo de la matrícula para Pregrado, Postgrado.",
                    "Plazos hasta 6 meses para que no pierdas esa oportunidad de estudio.",
                    "Cupo mínimo $300.000",
                    "El estudiante no necesariamente debe ser el titular del crédito, pero si codeudor de la obligación.",
                    "Cuota fija y desembolso se realiza directamente a la institución educativa.",
                    "Rápida aprobación con modalidad de financiación directa o con aval."
                ],
                interestRate: "Desde* 1.69% M.V. equivalente a 22.28% ",
                interestType: "E.A.",
                requiremets: [
                    {
                        requirement: [
                            "Edad: 18 a 74 años.",
                            "Ingresos: desde 1 SMMLV.",
                            "Estabilidad laboral:1 año (Asalariado o pensionado) 1 año (Independiente).",
                            "Plazo Mínimo 3 y hasta 6 meses para pregrado y hasta 12 meses para postgrados."
                        ]
                    }
                ]
            },
        ]
    },
    {
        institute: "BBVA",
        link: "https://www.bbva.com.co/personas/productos/prestamos/consumo/educacion.html",
        image: "../../assets/svg/bbva.svg",
        principalHeadquarter: {
            city: "Bogotá D.C.",
            region: "Cundinamarca",
            address: "Carrera 9 # 72  21"
        },
        callCenterHours: {
            startDay: "Lunes",
            endDay: "sábado",
            schedule: "7:00 a.m. a 10:00 p.m.",
            weekendDay: "Domingos y festivos",
            weekendStartHour: "8:00 a.m.",
            weekendEndHour: "10:00 p.m."

        },
        numberChannels: [
            {
                city: "Barranquilla",
                diallingCode: "(+57)",
                phoneNumber: "605 3503500  "
            },
            {
                city: "Bogotá D.C.",
                diallingCode: "(+57)",
                phoneNumber: "601 4010000"
            },
            {
                city: "Medellín",
                diallingCode: "(+57)",
                phoneNumber: "604 4938300"
            },
            {
                city: "Línea nacional (gratuita)",
                diallingCode: "",
                phoneNumber: "01 8000 912 227"
            }
        ],
        socialMedia: [
            {
                title: "X",
                link: "https://twitter.com/bbva_colombia"
            },
            {
                title: "Facebook",
                link: "https://www.facebook.com/bbvaencolombia/"
            },

        ],
        creditPrograms: [
            {
                title: "Crédito educativo",
                link: "https://www.bbva.com.co/personas/productos/prestamos/consumo/educacion.html",
                benefits: [
                    "Financia tu crecimiento profesional.",
                    "Disfruta de tu Crédito Educativo con una tasa mensual fija.",
                    "Elige al titular del crédito. Puede ser alguno de los padres, el acudiente o el estudiante.",
                    "Obtén cómodos plazos de financiación desde 6 hasta 12 meses.",
                    "$1 Millón Monto mínimo de préstamo",
                    "Utiliza el Crédito Educativo para pagar la matrícula ordinaria o extraordinaria de carreras profesionales, tecnólogas, técnicas o de posgrado.",
                    "Garantiza tu Crédito Educativo con la firma del pagaré.",
                    "Amortiza tu pago anual en 12 cuotas."

                ],
                interestRate: "Desde* 1.69% M.V. equivalente a 22.28% ",
                interestType: "E.A.",
                requiremets: [
                    {
                        type: "Empleado",
                        requirement: [
                            "Solicitud de vinculación como persona natural. ",
                            "Fotocopia del documento ampliado al 150%.",
                            "Certificado laboral original con vigencia máxima a 90 días, indicando cargo, salario, tiempo de servicio y tipo de contrato.",
                            "Fotocopia del último comprobante de pago de nómina o pensión. Si los ingresos son variables, adjuntar comprobante de los tres últimos meses.",
                            "Contratos, certificados o documentos que acrediten otros ingresos.",
                            "Fotocopia de la orden de matrícula."
                        ]
                    },
                    {
                        type: "Pensionado",
                        requirement: [
                            "Solicitud de vinculación como persona natural.",
                            "Fotocopia del documento ampliado al 150%.",
                            "Fotocopia del último comprobante de pago de nómina o pensión.",
                            "Contratos, certificados o documentos que acrediten otros ingresos.",
                            "Fotocopia de la orden de matrícula."
                        ]
                    },
                    {
                        type: "Independiente",
                        requirement: [
                            "Solicitud de vinculación como persona natural.",
                            "Fotocopia del documento ampliado al 150%.",
                            "Fotocopia del certificado de ingresos y retenciones y/o declaración de renta.",
                            "Contratos, certificados o documentos que acrediten otros ingresos.",
                            "Extractos bancarios de los últimos tres meses.",
                            "Certificado de Cámara y Comercio vigente.",
                            "Fotocopia de la orden de matrícula."
                        ]
                    },
                    {
                        type: "Prestador de servicios",
                        requirement: [
                            "Solicitud de vinculación como persona natural.",
                            "Fotocopia del documento ampliado al 150%.",
                            "Certificado laboral original con vigencia máxima a 90 días, indicando cargo, salario, tiempo de servicio y tipo de contrato.",
                            "Certificado de ingresos y retenciones y/o Declaración de renta.",
                            "Fotocopia del último comprobante de pago de nómina o pensión. Si los ingresos son variables, adjuntar comprobante de los tres últimos meses.",
                            "Contratos, certificados o documentos que acrediten otros ingresos.",
                            "Fotocopia de la orden de matrícula."
                        ]
                    },
                    {
                        type: "Rentista de capital",
                        requirement: [
                            "Solicitud de vinculación como persona natural.",
                            "Fotocopia del documento ampliado al 150%.",
                            "Contratos de arrendamiento vigentes y/o certificación de inmobiliaria con vigencia no mayor a 30 días.",
                            "Certificado de tradición y libertad de los inmuebles de los que obtiene ingresos, con vigencia no mayor a 90 días",
                            "Títulos valores o documentos que reporten el capital invertido generador de rendimientos",
                            "Contratos, certificados o documentos que acrediten otros ingresos.",
                            "Fotocopia de la orden de matrícula."
                        ]
                    }
                ]

            },
        ]
    },
    {
        institute: "Pichincha",
        link: "https://pichincha.credyty.com/",
        image: "../../assets/svg/bancopichincha.svg",
        principalHeadquarter: {
            city: "Bogotá D.C.",
            region: "Colombia",
            address: "Avenida Las Américas 42 81"
        },
        callCenterHours: {
            startDay: "Lunes",
            endDay: "domingo",
            schedule: "Atención 24 horas 365 días del año",


        },
        numberChannels: [
            {
                city: "Bogotá D.C.",
                diallingCode: "(+57)",
                phoneNumber: "601 6501000"
            },
            {
                city: "Línea nacional (gratuita)",
                diallingCode: "",
                phoneNumber: "01 8000 919 918"
            }
        ],
        socialMedia: [
            {
                title: "Instagram",
                link: "https://www.instagram.com/bancopichinchacol/?hl=es-la"
            },
            {
                title: "Facebook",
                link: "https://www.facebook.com/BancoPichinchaCol"
            },
            {
                title: "X",
                link: "https://twitter.com/BancoPichincha"
            }

        ],
        creditPrograms: [
            {
                title: "Crédito educativo de corto plazo",
                link: "https://pichincha.credyty.com/",
                benefits: [
                    "Ahorra tiempo sin ir al banco.",
                    "Sin documentos ni trámites presenciales.",
                    "Aprobación de crédito en línea.",
                    "Más de 50 universidades aliadas.",
                    "Financiamos hasta el 100% del valor de la matrícula, desde $300.000 hasta $30.000.000",
                    "Financiamos programas de pregrado, posgrado, y estudios especializados, en más de 500 instituciones a nivel nacional.",
                    "Cada utilización podrá diferirse entre 3 y 24 meses (De acuerdo a la duración del periodo académico)."

                ],
                interestRate: "Desde 1.46% M.V. equivalente a 19% ",
                interestType: "E.A.",
                requiremets: [
                    {
                        requirement: [
                            "El responsable del crédito debe ser mayor de edad, debe tener ingresos mayores a un salario mínimo legal y una cuenta de correo y número celular para hacer el registro y hacer validación de datos.",
                            "Línea de Crédito Rotativa: el Banco aprueba un cupo, el cual podrá usarse únicamente en Universidades aliadas. Mientras se realicen abonos a la obligación se liberará el saldo disponible.",
                            "El titular de la obligación podrá escoger su fecha de pago para los días 5 o 20 de cada mes."
                        ]
                    }
                ]
            },
            {
                title: "Crédito educativo de largo plazo",
                link: "https://pichincha.credyty.com/",
                benefits: [
                    "Ahorra tiempo sin ir al banco.",
                    "Sin documentos ni trámites presenciales.",
                    "Aprobación de crédito en línea.",
                    "Más de 50 universidades aliadas.",
                    "Financiamos hasta el 100% del valor de la matrícula, desde $5,000,000 hasta $160,000,000.",
                    "Financiación de 12 a 60 meses.",
                    "Financiamos programas de pregrado, posgrado, y estudios especializados, en más de 500 instituciones a nivel nacional.",

                ],
                interestRate: "Desde 1.56% M.V. equivalente a 20.41% ",
                interestType: "E.A.",
                requiremets: [
                    {
                        requirement: [
                            "El responsable del crédito debe ser mayor de edad, debe tener ingresos mayores a un salario mínimo legal y una cuenta de correo y número celular para hacer el registro y hacer validación de datos.",
                            "Línea de crédito tradicional con desembolsos parciales: Te aprobamos el valor total de la carrera universitaria y en cada periodo académico, se realiza el desembolso a la universidad, de acuerdo a la solicitud del titular del crédito.",
                            "Pagas la primera cuota 30 días después del primer desembolso parcial.",
                            "Puedes solicitar el crédito en conjunto con uno o más deudores solidarios para sumar ingresos."
                        ]
                    }
                ]
            },
        ],
        instituteNote: "El crédito es para financiar montos entre $500.000 y $30.000.000, financiados de 3 a 12 cuotas y que dependen de las condiciones de cada programa. Adicional, en el crédito se cobran conceptos adicionales como tarifa de tecnología, tasa de interés nominal mes vencido, costo del seguro de vida, y costos operativos del procesamiento en el fondo de garantías."
    },
    {
        institute: "Davivienda",
        link: "https://www.davivienda.com/wps/portal/personas/nuevo/personas/aqui_puedo/construir_mi_futuro/educacion/!ut/p/z1/pZFNC8IwDIZ_i4cdbWKns_VWBcWBX8hw5iKbzDlw65jT4r_XiRdBi2BuIc-b5E2AIAQqomuWRnWmi-j0yLfk7Vyx6s79IZ-tx9JFNcP1SMlFB5UHGxswkT0gS3nZs-sXE_6bHr-Ewr_mNwbJ3n4DZB3RXMAGPC3aABz3X4BlCx8oi3Nm9jlDJrnX56Ljco8jF0I2T1RF7IoUqEoOSZVU7FI9fnus6_I8cNBBYwxLtU5PCdvr3MFPkqM-1xC-k1DmQRCEmE3bFN-Mat0BocKc3g!!/dz/d5/L2dBISEvZ0FBIS9nQSEh/",
        image: "../../assets/svg/davivienda.svg",
        principalHeadquarter: {
            city: "Bogotá D.C.",
            region: "Colombia",
            address: "Avenida El Dorado # 68C-61"
        },
        callCenterHours: {
            startDay: "Lunes",
            endDay: "domingo",
            schedule: "Atención 24 horas.",


        },
        numberChannels: [
            {
                city: "Bogotá D.C.",
                diallingCode: "(+57)",
                phoneNumber: "601 3278360"
            },
            {
                city: "Línea nacional (gratuita)",
                diallingCode: "",
                phoneNumber: "01 8000 919 561"
            },
            {
                city: "Estados Unidos",
                diallingCode: "(+1)",
                phoneNumber: "833 9573443 "
            },
            {
                city: "España",
                diallingCode: "(+00)",
                phoneNumber: "8000  3383838"
            }
        ],
        socialMedia: [
            {
                title: "Instagram",
                link: "https://www.instagram.com/davivienda/"
            },
            {
                title: "Facebook",
                link: "https://www.facebook.com/BancoDavivienda"
            },

        ],
        creditPrograms: [
            {
                title: "Crédito educativo fijo",
                link: "https://www.davivienda.com/wps/portal/personas/nuevo/personas/aqui_puedo/construir_mi_futuro/educacion/credito_universitario/f5048652-0310-4fea-9254-c55a8e80368a/!ut/p/z1/pVPBcoIwEP2WHjzCbhICoTfEakdLrTpWyaWDFJGpgEUq9e8LjtMZ2xo7494yeW-zb98LSJiDzIJdEgdlkmfBuj770nxhYmQ89tvUc4eUo-N57U7H8sjIRJidAiZdm9UAnLiOPSQ9m4NUXD9xNX_Yoyo-OuaRj2fKwf_xFQB5hX4csOveH5BL_Gfw2-A_vJef_epns9_TSvWuZiCVfjV2qgAHv5QTd60jQOWYskOzUjWAQB9kskj1Kkx11G1qWlQQRk2KVAi7ybSTLZiIQRbRMiqiQv8o6qivynKzvW1hC6uq0uM8j9eRHuZpC_-irPJtCfNTJPi1AdZ5AwjMdklUwTTLi7T-XZNGyiZMXsFfcjSEyamGjKBmLKNAsyk3tJDzQEQCmSkCuMdG2oVAut9xkAQ26fRQ2V57G9-NxZ6td6m7EF7u3HwBkBGObA!!/dz/d5/L2dBISEvZ0FBIS9nQSEh/",
                benefits: [
                    "Sin cobro por el estudio de crédito.",
                    "Sin sanción por prepago.",
                    "Cuota y tasa fija durante la vigencia del crédito.",
                    "Aplica para carreras profesionales, técnicas, tecnólogas o de posgrado.",
                    "Financiación hasta el 100% del valor de la matrícula.",
                    "Se podrá solicitar con un plazo desde 1 hasta 72 meses.",
                    "Cuenta con seguro de deudores.",
                    "Cuenta con 3 opciones de financiación: Plan Tradicional, Periodos de Gracia y Cuotas Extraordinarias."

                ],
                interestRate: "Desde 1.66% M.V. equivalente a 21.84% ",
                interestType: "E.A.",
                requiremets: [
                    {
                        type: "Empleados",
                        requirement: [
                            "Monto mínimo a financiar: $1.000.000.",
                            "Ingresos mínimos mensuales requeridos: un salario mínimo legal vigente (1 SMLV).",
                            "Desembolso en cheque a nombre de la universidad o institución educativa.",
                            "El plazo no se modifica durante la vigencia del crédito.",
                            "La fotocopia de la cédula al 150%",
                            "Copia del recibo de matrícula vigente, correspondiente al período académico a cursar.",
                            "Solicitud condiciones especiales Crédito Educativo.",
                            "Carta de compromiso compra de Cartera/Crédito educativo."

                        ]
                    },
                    {
                        type: "Empleados",
                        requirement: [
                            "Monto mínimo a financiar: $1.000.000.",
                            "Ingresos mínimos mensuales requeridos: un salario mínimo legal vigente (1 SMLV).",
                            "Desembolso en cheque a nombre de la universidad o institución educativa.",
                            "El plazo no se modifica durante la vigencia del crédito.",
                            "La fotocopia de la cédula al 150%",
                            "Copia del recibo de matrícula vigente, correspondiente al período académico a cursar.",
                            "Solicitud condiciones especiales Crédito Educativo.",
                            "Carta de compromiso compra de Cartera/Crédito educativo.",
                            "Certificación laboral, no mayor a 60 días, indicando salario, cargo, antigüedad y tipo de contrato."

                        ]
                    },
                    {
                        type: "Independientes",
                        requirement: [
                            "Monto mínimo a financiar: $1.000.000.",
                            "Ingresos mínimos mensuales requeridos: un salario mínimo legal vigente (1 SMLV).",
                            "Desembolso en cheque a nombre de la universidad o institución educativa.",
                            "El plazo no se modifica durante la vigencia del crédito.",
                            "La fotocopia de la cédula al 150%",
                            "Copia del recibo de matrícula vigente, correspondiente al período académico a cursar.",
                            "Solicitud condiciones especiales Crédito Educativo.",
                            "Carta de compromiso compra de Cartera/Crédito educativo.",
                            "Extractos bancarios de los últimos 3 meses.",
                            "Fotocopia de la declaración de renta del año inmediatamente anterior o carta de no declarante.",
                            "Debe tener mínimo 2 años en la actividad como independiente.",
                            "Importante: Para créditos mayores o iguales a $100 Millones lo invitamos a consultar la sección Empresas > Pyme. "
                        ]
                    },
                    {
                        type: "Pensionados",
                        requirement: [
                            "Monto mínimo a financiar: $1.000.000.",
                            "Ingresos mínimos mensuales requeridos: un salario mínimo legal vigente (1 SMLV).",
                            "Desembolso en cheque a nombre de la universidad o institución educativa.",
                            "El plazo no se modifica durante la vigencia del crédito.",
                            "La fotocopia de la cédula al 150%",
                            "Copia del recibo de matrícula vigente, correspondiente al período académico a cursar.",
                            "Solicitud condiciones especiales Crédito Educativo.",
                            "Carta de compromiso compra de Cartera/Crédito educativo.",
                            "Último desprendible de pago.",
                            "El banco le entregará los formatos correspondientes para su diligenciamiento y firma, puede mirar más a detalle en la página."

                        ]
                    },

                ]
            },
            {
                title: "Crédito educativo con garantía hipotecaria",
                link: "https://www.davivienda.com/wps/portal/personas/nuevo/personas/aqui_puedo/construir_mi_futuro/educacion/Credito%20Educativo%20con%20Garantia%20Hipotecaria/f5048652-0310-4fea-9254-c55a8e80368a/!ut/p/z1/pVNdb4IwFP0te_ARbluglL11DvF7TuaUviwgH5IJOGQy__3AmCVLZl3ifWt6zu0995yCgBWI3D-kiV-lRe5vm7Mn6JvuDMbYpjNO6YAg_mL33Z4zJ2hqwvIE0NizPh0-kInbszTEJ8jtcusJO5YBQnI9M-T8J4fI-IjTMx9dKI7-x5cAxA360Ui77f0RvsZ_Be8RvPFH9TWsz80k0wr5rpYgpH61dsoAJ7-kE_fMM0DmmLRDu1I5AMMQRBpkar3OVKRahJqEYY1QgghjVptpngcaS0CUURyVUal-lk3UN1W12993UAfVda0mRZFsI3VdZB30F2VT7CtY_UaC1xhgXjSgEb88pFENi7wos-Z3ua2U3ToNwUOGHxk6JQqOw0jRQz1QAp3Fio5jiwWBoYUEQR-10q4E0v6Jg8Cwyxanyo_K-9yes6O2PWTdgE0KfvcNqYgYCQ!!/dz/d5/L2dBISEvZ0FBIS9nQSEh/",
                benefits: [
                    "Se otorga a clientes que actualmente no tienen una garantía real constituida con el Banco y que hasta ahora la van a constituir para acceder al crédito; esta garantía debe ser un inmueble catalogado como Vivienda Mayor a VIS.",
                    "Sistema de amortización Fija o Variable.",
                    "Tasa anual indexada al DTF: Plazo 10 años.",
                    "Tasa fija en pesos: Plazo de 1 a 5 años.",
                    "Porcentaje de financiación: Máximo el 60% del valor comercial del inmueble.",
                    "El desembolso se realiza en cheque de gerencia a nombre de la universidad o institución educativa.",
                    "Utilización  del crédito para lo que necesite.",
                    "Sin  cobro de estudio de crédito.",
                    "Sin sanción por prepago."

                ],
                interestRate: "Desde 1.66% M.V. equivalente a 21.84% ",
                interestType: "E.A.",
                requiremets: [
                    {
                        requirement: [
                            "La fotocopia de la cédula al 150%",
                            "Certificado de Tradición y Libertad del inmueble con vigencia no mayor a 30 días.",
                            "Solicitud avalúo del inmueble y copia de la consignación (el cual solo puede ser realizado por los peritos autorizados por Davivienda).",
                            "Carta de compromiso compra de Cartera/Crédito educativo.",
                            "Copia del recibo de matrícula vigente, correspondiente al período académico a cursar.",
                            "El banco le entregará los siguientes formatos para su diligenciamiento y firma: Solicitud de crédito. (Llene los campos necesarios a partir del numeral 2.), declaración de asegurabilidad, pagaré"

                        ]
                    },
                    {
                        type: "Independientes",
                        requirement: [
                            "Extractos bancarios de los últimos 3 meses.",
                            "Fotocopia de la declaración de renta del año inmediatamente anterior o carta de no declarante.",
                            "Debe tener mínimo 2 años en la actividad como independiente.",
                            "Importante: Para créditos mayores o iguales a $100 Millones lo invitamos a consultar la sección Empresas > Pyme. "
                        ]
                    },

                ]
            },
            {
                title: "Crédito educativo línea preferencial",
                link: "https://www.davivienda.com/wps/portal/personas/nuevo/personas/aqui_puedo/construir_mi_futuro/educacion/Credito%20Educativo%20Linea%20Preferencial/35b62a7d-b66a-4115-9328-10bcdb6efaff/!ut/p/z1/pVNdb4IwFP0te_ARbmmxlL1Vo_gxPyZzSl8WPiqSCRhkEP_9wJglS2Zd4n1res7tPfecgoAtiMyvktgvkzzzD83ZE_TDdMYvxoAuOaVjjPjbYOQOnRVGVhc2FwBhr-Z80sMzd2gTxGfI7XN7YTh2F4TienmHv3Cwio84vfLRjeLof3wFQDygH03JY-9PjXv8d_B64FVlbtTxtZliWqHe1QaE0q_WThXg4pdy4qF1BagcU3ZoV6oGGDABkQSpXoepjnQbUwszg2CKEWbMbjPNs4CwGEQhd7KQhf5VNFHfl-Xx9NxBHVTXtR7neXyQepinHfQXZZ-fStj-RoLXGGDdNGDexKVKZA3rLC_S5ne5rZRjmETg-RHxJTEDzYx2pmZSZmoMS1ujEWVREAaShAxGqJV2J5D9nzgIA47p-lLZWftcDVbsTA5V2g_YLOdP35XTx7M!/dz/d5/L2dBISEvZ0FBIS9nQSEh/",
                benefits: [
                    "Crédito con destino Educativo.",
                    "Sin cobro por el estudio de crédito.",
                    "Pago a través de débito automático, canales electrónicos y oficinas.",
                    "Se otorga a clientes con crédito hipotecario o leasing habitacional con inmuebles mayores a VIS.",
                    "Sistema de amortización Fija o Variable.",
                    "Tasa anual indexada al DTF: Plazo 10 años.",
                    "Tasa fija en pesos: Plazo de 1 a 5 años.",
                    "Porcentaje de financiación: Máximo el 60% del valor comercial del inmueble incluyendo la deuda actual que tenga en el Crédito Hipotecario o Leasing Habitacional.",
                    "El desembolso se realiza en cheque de gerencia a nombre de la universidad o institución educativa.",

                ],
                interestRate: "Desde 1.66% M.V. equivalente a 21.84% ",
                interestType: "E.A.",
                requiremets: [
                    {
                        type: "Empleados",
                        requirement: [
                            "Certificación laboral, no mayor a 60 días, indicando salario, cargo, antigüedad y tipo de contrato.",
                            "La fotocopia de la cédula al 150%",
                            "Copia del recibo de matrícula vigente, correspondiente al período académico a cursar.",
                            "Solicitud condiciones especiales Crédito Educativo.",
                            "Carta de compromiso compra de Cartera/Crédito educativo."

                        ]
                    },
                    {
                        type: "Independientes",
                        requirement: [
                            "Extractos bancarios de los últimos 3 meses.",
                            "Fotocopia de la declaración de renta del año inmediatamente anterior o carta de no declarante.",
                            "Debe tener mínimo 2 años en la actividad como independiente.",
                            "Importante: Para créditos mayores o iguales a $100 Millones lo invitamos a consultar la sección Empresas > Pyme. ",
                            "La fotocopia de la cédula al 150%",
                            "Copia del recibo de matrícula vigente, correspondiente al período académico a cursar.",
                            "Solicitud condiciones especiales Crédito Educativo.",
                            "Carta de compromiso compra de Cartera/Crédito educativo."
                        ]
                    },
                    {
                        type: "Pensionados",
                        requirement: [
                            "La fotocopia de la cédula al 150%",
                            "Copia del recibo de matrícula vigente, correspondiente al período académico a cursar.",
                            "Solicitud condiciones especiales Crédito Educativo.",
                            "Carta de compromiso compra de Cartera/Crédito educativo.",
                            "Último desprendible de pago.",
                            "El banco le entregará los formatos correspondientes para su diligenciamiento y firma, puede mirar más a detalle en la página."

                        ]
                    },

                ]
            },
            {
                title: "Crédito rotativo educativo",
                link: "https://www.davivienda.com/wps/portal/personas/nuevo/personas/aqui_puedo/construir_mi_futuro/educacion/Cr%C3%A9dito%20Educativo%20Rotativo%20Para%20Toda%20la%20Carrera/f5048652-0310-4fea-9254-c55a8e80368a/!ut/p/z1/pVNNb4JAFPwtPXiEtx8IS29bq7QorZVYZS8NXyKpsAYpxH9fMKZJk7o28d02O_P2zZtZELAGUYZNnoV1Lstw150DYX4YzvMMj80595hhI05nruWiIXVGGFYnAGVvxov7QDx_YlPEPeSPuP2KHXsIQnE9H6r5rw5R8RE3z3x0oTj6H18BEDfoR1N62_tTfI3_DsEjBE0tcZudmymmFepdrUAo_ertVAFOfiknnlhngMoxZYd-pWoABhdEHhV6Gxc60m1iWoRhSkyCCGN2n2leRpRlIKp0k1ZppX9VXdS3db0_3A_QALVtq2dSZrtUj2UxQH9RtvJQw_o3EoLOAOuiAX4XlyZPW1iWsiq63-X3UvZxnkAQhzhOTBRpJCS2ZhimpbEEbzTb2KSRlcZhFCbwhHppVwI5_omDwLAvlqcqj9rnYrxgR7prilHEPMnvvgGEfsSc/dz/d5/L2dBISEvZ0FBIS9nQSEh/",
                benefits: [
                    "Personas que deseen financiar sus estudios de pregrado o educación superior y los de su familia con un plazo de 6 o 12 meses.",
                    "Aplica para carreras profesionales, técnicas, tecnólogas o de posgrado.",
                    "Sin cobro por el estudio de crédito.",
                    "Sin sanción por prepago.",
                    "A medida que se realizan los pagos se va liberando el cupo del crédito.",
                    "Ingresos mínimos mensuales requeridos: un salario mínimo legal vigente (1 SMLV).",
                    "Cuenta con seguro de deudores.",
                ],
                interestRate: "Desde 1.66% M.V. equivalente a 21.84% ",
                interestType: "E.A.",
                requiremets: [
                    {
                        type: "Empleados",
                        requirement: [
                            "Certificación laboral, no mayor a 60 días, indicando salario, cargo, antigüedad y tipo de contrato.",
                            "La fotocopia de la cédula al 150%",
                            "Copia del recibo de matrícula vigente, correspondiente al período académico a cursar.",
                        ]
                    },
                    {
                        type: "Independientes",
                        requirement: [
                            "Extractos bancarios de los últimos 3 meses.",
                            "Fotocopia de la declaración de renta del año inmediatamente anterior o carta de no declarante.",
                            "Debe tener mínimo 2 años en la actividad como independiente.",
                            "Importante: Para créditos mayores o iguales a $100 Millones lo invitamos a consultar la sección Empresas > Pyme. ",
                            "La fotocopia de la cédula al 150%",
                            "Copia del recibo de matrícula vigente, correspondiente al período académico a cursar.",

                        ]
                    },
                    {
                        type: "Pensionados",
                        requirement: [
                            "La fotocopia de la cédula al 150%",
                            "Copia del recibo de matrícula vigente, correspondiente al período académico a cursar.",
                            "Último desprendible de pago.",
                            "El banco le entregará los formatos correspondientes para su diligenciamiento y firma, puede mirar más a detalle en la página."

                        ]
                    },

                ]
            },
        ],
        instituteNote: "Aplica para estudios de educación superior en instituciones avaladas por el Ministerio de Educación Nacional."
    },
    {
        institute: "Banco de Bogotá",
        link: "https://www.bancodebogota.com/wps/portal/banco-de-bogota/bogota/productos/para-ti/creditos-y-financiacion/crediestudiantil#tab-3",
        image: "../../assets/svg/bancoDeBogota.svg",
        principalHeadquarter: {
            city: "Bogotá D.C.",
            region: "Colombia",
            address: " Calle 36 # 7 - 47 Piso 14"
        },
        callCenterHours: {
            startDay: "Lunes",
            endDay: "domingo",
            schedule: "Atención 24 horas 365 días del año",


        },
        numberChannels: [
            {
                city: "Bogotá D.C.",
                diallingCode: "(+57)",
                phoneNumber: "601 3820000"
            },
            {
                city: "Barranquilla",
                diallingCode: "(+57)",
                phoneNumber: "605 3504300"
            },
            {
                city: "Medellín",
                diallingCode: "(+57)",
                phoneNumber: "604 5764330"
            },
            {
                city: "Cali",
                diallingCode: "(+57)",
                phoneNumber: "602 8980077"
            },
            {
                city: "Línea nacional (gratuita)",
                diallingCode: "",
                phoneNumber: "01 8000 518 877"
            }
        ],
        socialMedia: [
            {
                title: "Facebook",
                link: "https://www.facebook.com/BancodeBogota"
            },
            {
                title: "X",
                link: "https://twitter.com/BancodeBogota"
            }

        ],
        creditPrograms: [
            {
                title: "Crediestudiantil",
                link: "https://www.bancodebogota.com/wps/portal/banco-de-bogota/bogota/productos/para-ti/creditos-y-financiacion/crediestudiantil#tab-3",
                benefits: [
                    "Estudio de crédito gratuito en los stands de universidades.",
                    "La tasa de interés es fija y desde el primer momento podrás conocer el valor exacto de la cuota.",
                    "Puedes tramitar el crédito en nuestras oficinas o en los stands del banco ubicados en las principales universidades a nivel nacional.",
                    "Recibes extracto mensual y consulta la información de tu crédito a través de los diferentes canales que tenemos para tu disposición.",
                    "Facilidad y comodidad en el pago de la matrícula, ya que el plazo de pago de ajusta a tus necesidades.",
                    "Sin costos adicionales por fondos de garantías.",
                    "Puedes pagar antes de la fecha estipulada sin que eso conlleve a una penalidad o sanción."

                ],
                interestRate: "1.74% M.V. equivalente a 22.98% ",
                interestType: "E.A.",
                requiremets: [
                    {
                        type: "Empleado",
                        requirement: [
                            "Declaración de renta del último año gravable. Si no debes declarar, presenta la certificación de no declarante.",
                            "Certificación de ingresos y retenciones del último año gravable.",
                            "Certificado laboral original no mayor a 30 días indicando cargo, sueldo, antigüedad y tipo de contrato. (Si tienes contrato a término indefinido, debe ser mayor a 6 meses o a término fijo superior a un año).",
                            "Si vas a solicitar el crédito, debes tener ingresos mínimos de 1.5 SMMLV. Además, debes anexar la fotocopia de la orden de matrícula y el documento de identidad del solicitante.",
                            "El crédito se desembolsa directamente a la universidad y la garantía es con pagaré.",
                            "El plazo de pago corresponde a tu ciclo de estudio. De esta manera, para pregrados semestrales, tienes 6 meses para pagar la financiación de la matrícula; 12 meses para programas anuales y para posgrados o diplomados el plazo es de 12 a 36 meses.",
                            "Si no eres asalariado requieres de documentos que comprueben tus ingresos. Se te solicitarán otros papeles dependiendo si eres trabajador independiente o pensionado."
                        ]
                    }
                ]
            },

        ],

    },
    {
        institute: "Banco Av Villas",
        link: "https://www.avvillas.com.co/wps/portal/avvillas/banco/banca-personal/productos/prestamos/credito-para-estudio-av-villas/!ut/p/z1/04_Sj9CPykssy0xPLMnMz0vMAfIjo8zifQIszTwsTQx8DAL93QwcDT2dDRydggwsAs31w1EVWAT4WBg4hnp4eZj5uxgHe5jqRxGj3wAHcDQgTj8eBVH4jQ_Xj0K1AsUHXi4hZvgVGDua4FVgHBxiQEABKJAIObMgNzQ0wiDTM9NRUREA7i_LxQ!!/",
        image: "../../assets/svg/bancoAvVillas.svg",
        principalHeadquarter: {
            city: "Bogotá D.C.",
            region: "Colombia",
            address: "Carrera 13 No. 26A-47 "
        },
        callCenterHours: {
            startDay: "Lunes",
            endDay: "domingo",
            schedule: "Atención 24 horas 365 días del año",


        },
        numberChannels: [
            {
                city: "Bogotá D.C.",
                diallingCode: "(+57)",
                phoneNumber: "601 4441777"
            },
            {
                city: "Barranquilla",
                diallingCode: "(+57)",
                phoneNumber: "605 3304330"
            },
            {
                city: "Medellín",
                diallingCode: "(+57)",
                phoneNumber: "604 3256000"
            },
            {
                city: "Cali",
                diallingCode: "(+57)",
                phoneNumber: "602 8859595"
            },
            {
                city: "Línea nacional (gratuita)",
                diallingCode: "",
                phoneNumber: "01 8000 518 000"
            }
        ],
        socialMedia: [
            {
                title: "Facebook",
                link: "https://www.facebook.com/AVVillas/"
            },
            {
                title: "Instagram",
                link: "https://www.instagram.com/avvillas/"
            }

        ],
        creditPrograms: [
            {
                title: "Crédito educativo",
                link: "https://www.avvillas.com.co/wps/portal/avvillas/banco/banca-personal/productos/prestamos/credito-para-estudio-av-villas/!ut/p/z1/04_Sj9CPykssy0xPLMnMz0vMAfIjo8zifQIszTwsTQx8DAL93QwcDT2dDRydggwsAs31w1EVWAT4WBg4hnp4eZj5uxgHe5jqRxGj3wAHcDQgTj8eBVH4jQ_Xj0K1AsUHXi4hZvgVGDua4FVgHBxiQEABKJAIObMgNzQ0wiDTM9NRUREA7i_LxQ!!/",
                benefits: [
                    "Se realizará un sólo estudio de crédito y la aprobación será por toda la carrera.",
                    "Financiamos los siguientes estudios de educación superior: Técnico, tecnólogo, pregrado, diplomado, especializaciones, maestría, doctorados",
                    "Se financia hasta el 100% del valor de la matricula",
                    "El estudio de crédito NO tiene costo.",
                    "Te informamos a través de mensajes de texto a tu celular el estado de la solicitud de crédito.",
                    "Te permite controlar y programar tus finanzas personales ya que conoces el valor único de la cuota mensual (capital más intereses) desde el primer mes y hasta que termines de pagar tu crédito.",
                    "El Banco AV Villas no cobra sanción por prepago.",
                    "No se realiza la financiación de las carreras: Militares, aviación, belleza, cursos de inglés",
                    "Cuentas con un Seguro de Vida del deudor durante la vigencia de tu crédito.",
                    "Te ofrecemos un servicio de aprobación de 3 horas",
                    "El crédito Educativo cuenta con plazos desde: Corto Plazo: 1 Periodo; La duración del programa académico hasta 60 meses, más el plazo que el cliente solicite, 24, 36, 48 y 60 meses adicionales después del último desembolso.",
                    "Puedes realizar tus pagos y consultas en cualquier oficina del Banco AV Villas o en nuestros canales: AV Villas App, Banca Virtual ó Audiovillas.",
                    "Posibilidad de generar puntos tuplús los cuales puedes convertir en dinero para abonar a tu cuenta corriente, de ahorros o tarjetas de crédito, vigentes con el banco",
                    "Recibes los desembolsos en cheque de gerencia girado a la Universidad.",
                    "Puedes financiar matrículas de montos iguales o superiores a $1.500.000."


                ],
                interestRate: "21.75% ",
                interestType: "E.A.",
                requiremets: [
                    {
                        type: "Empleado",
                        requirement: [
                            "Para la solicitud de un Credivillas Educativo debes traer el recibo de pago u orden de matrícula expedida por la universidad y la fotocopia de tu documento de identidad ampliada al 150%.",
                            "Certificado Laboral no mayor a 30 días especificando cargo, antigüedad, salario y tipo de contrato.",
                            "Comprobantes de pago del último mes o dos últimas quincenas."
                        ]
                    },
                    {
                        type: "Pensionados",
                        requirement: [
                            "Para la solicitud de un Credivillas Educativo debes traer el recibo de pago u orden de matrícula expedida por la universidad y la fotocopia de tu documento de identidad ampliada al 150%.",
                            "Comprobante de pago del último mes."
                        ]
                    }
                ],
            },

        ],
        instituteNote: "Sujeto a políticas de crédito, aplican condiciones y políticas del banco."
    },
    {
        institute: "Banco de Occidente",
        link: "https://www.bancodeoccidente.com.co/wps/portal/banco-de-occidente/bancodeoccidente/para-personas/otros/universidad-garantizada#undefined1",
        image: "../../assets/svg/bancoOccidente.svg",
        principalHeadquarter: {
            city: "Cali",
            region: "Colombia",
            address: "Carrera 4 # 7-61"
        },
        callCenterHours: {
            startDay: "Lunes",
            endDay: "domingo",
            schedule: "Atención 24 horas 365 días del año",


        },
        numberChannels: [
            {
                city: "Bogotá D.C.",
                diallingCode: "(+57)",
                phoneNumber: "601 3077027"
            },
            {
                city: "Barranquilla",
                diallingCode: "(+57)",
                phoneNumber: "605 3305000"
            },
            {
                city: "Medellín",
                diallingCode: "(+57)",
                phoneNumber: "604 2317700"
            },
            {
                city: "Cali",
                diallingCode: "(+57)",
                phoneNumber: "602 8959256"
            },
            {
                city: "Línea nacional (gratuita)",
                diallingCode: "",
                phoneNumber: "01 8000 514 652"
            }
        ],
        socialMedia: [
            {
                title: "Facebook",
                link: "https://www.facebook.com/BcoOccidente/"
            },
            {
                title: "Instagram",
                link: "https://www.instagram.com/bco_occidente/"
            },
            {
                title: "X",
                link: "https://twitter.com/Bco_Occidente"
            }

        ],
        creditPrograms: [
            {
                title: "Universidad Garantizada",
                link: "https://www.bancodeoccidente.com.co/wps/portal/banco-de-occidente/bancodeoccidente/para-personas/otros/universidad-garantizada#/undefined1",
                benefits: [
                    "Financiamos hasta el 100% de la póliza educativa.",
                    "Se manejan tasas fijas con cuotas fijas mensuales y tasas variables indexadas a la IBR con cuotas variables mensuales.",
                    "Monto mínimo de $2.000.000.",
                    "Plazo de 12 hasta 60 meses",
                    "Monto máximo de 300 S.M.L.V. Se evalúa de acuerdo a capacidad de endeudamiento y se solicitan condiciones especiales y garantías.",
                    "Desde la contratación hasta el tercer año de vigencia, la póliza garantiza el crédito en el 65%, a partir del mes veinticinco la póliza garantiza el crédito en el 100%”.",
                    "El porcentaje de financiación cubre hasta el 100% del valor de la póliza, sujeto a estudio del área de crédito.",
                    "Cuota fija mensual o cuota variable.",
                    "La base de liquidación de intereses es días comerciales (360 días).",
                    "El cliente debe pagar una cuota fija mensual o alícuota con tasa fija, variable o tasa DTF, cuya primera fecha de pago puede ser pactada entre los 31 y 45 días siguientes a la contabilización del crédito. El valor de la alícuota incluye abono a capital más intereses corrientes y seguro de vida.",
                    "Los vencimientos deben programarse únicamente para los días 2, 3, 16 y 17 de cada mes.",
                    "El cobro del seguro es mes vencido, y el factor se cobra hasta el vencimiento final del crédito",
                    "El desembolso se hace directamente a Global Seguros de Vida S.A.",
                    "Los pagos pueden ser realizados en efectivo, cheque del Banco local o débito automático a la cuenta de ahorros o corriente.",
                    "Puedes realizar abonos extraordinarios lo que permite reducir el plazo o el valor de las cuotas.",
                    "No requieres codeudor.",
                    "El estudio de tu crédito es gratis."

                ],
                interestRate: "21.75% ",
                interestType: "E.A.",
                requiremets: [
                    {
                        type: "Empleado",
                        requirement: [
                            "Si ya eres cliente del Banco de Occidente, Descarga la Solicitud de Productos.",
                            "Si aún no eres cliente del Banco de Occidente descarga la Solicitud de Vinculación.",
                            "Guía de uso Universidad Garantizada.",
                            "Documentación requerida para tu crédito rotativo.",
                            "Fotocopia del documento de identidad ampliado al 150%.",
                            "Certificación laboral indicando salario, antigüedad, tipo de contrato y cargo, con fecha de expedición no mayor a 60 días.",
                            "Declaración de Renta, si los ingresos principales (mayor al 60%) son honorarios. O extractos bancarios de los últimos 3 meses en caso de no declarar."
                        ]
                    },
                    {
                        type: "Independientes",
                        requirement: [
                            "Si ya eres cliente del Banco de Occidente, Descarga la Solicitud de Productos.",
                            "Si aún no eres cliente del Banco de Occidente descarga la Solicitud de Vinculación.",
                            "Guía de uso Universidad Garantizada.",
                            "Documentación requerida para tu crédito rotativo.",
                            "Fotocopia del documento de identidad ampliado al 150%.",
                            "Certificado de Cámara y Comercio con vigencia menor a 90 días.",
                            "Declaración de renta del último año y estados financieros de corte parcial, junto con la Tarjeta Profesional del Contador.",
                            "Si no declara renta, Estados Financieros (un año completo y un corte parcial) firmado por el Contador, junto con la Tarjeta Profesional del Contador.",
                            "Si no declara renta Extractos Bancarios de cuentas corrientes y/o ahorros de los últimos 3 meses."
                        ]
                    }
                ],
            },

        ],
        instituteNote: "Se solicitará documentación adicional dependiendo de la actividad económica del solicitante. Aplica para personas desde los 23 años de edad.Personas con ingresos dependiendo de la ciudad y actividad económica(revisar en la página)"
    },
    {
        institute: "Lulo Bank",
        link: "https://www.lulobank.com/features/pide-tu-credito",
        image: "../../assets/svg/lulobank.svg",
        principalHeadquarter: {
            city: "Bogotá D.C.",
            region: "Colombia",
            address: "Carrera 7 No. 71-52 TO B"
        },
        socialMedia: [
            {
                title: "X",
                link: "https://twitter.com/LuloBank"
            },
            {
                title: "Instagram",
                link: "https://www.instagram.com/lulobank"
            }

        ],
        creditPrograms: [
            {
                title: "Crédito libre destino",
                link: "https://www.lulobank.com/features/pide-tu-credito",
                benefits: [
                    "Hasta por $50 millones",
                    "Recíbelo en la Lulo cuenta en minutos o en la cuenta del banco que prefieras en máximo 3 días hábiles.",
                    "Plazos hasta de 48 meses"

                ],
                interestRate: "Desde 1.40% M.V. equivalente a 18.12%",
                interestType: "E.A.",
                requiremets: [
                    {
                        requirement: [
                            "Tener cédula de ciudadanía colombiana.",
                            "Celular registrado en Colombia.",
                            "Tener entre 21 y 65 años.",
                            "Contar con ingresos superiores a $2 millones de pesos."
                        ]
                    },
                ],
            },
        ],
        instituteNote: "Aprobación sujeta a evaluación del crédito"
    },
    {
        institute: "Financiera Comultrasan",
        link: "https://www.financieracomultrasan.com.co/es/personas/productos-y-servicios/para-crecer-credito/credito-educativo",
        image: "../../assets/svg/comultrasan.svg",
        principalHeadquarter: {
            city: "Bucaramanga",
            region: "Colombia",
            address: "Calle 35 # 16-43 Paseo del Comercio"
        },
        callCenterHours: {
            startDay: "Lunes",
            endDay: "domingo",
            schedule: "Atención 24 horas 365 días del año",


        },
        numberChannels: [
            {
                city: "Bucaramanga",
                diallingCode: "(+57)",
                phoneNumber: "607 6803003"
            },
            {
                city: "Línea nacional (gratuita)",
                diallingCode: "",
                phoneNumber: "01 8000 514 652"
            }
        ],
        socialMedia: [
            {
                title: "Facebook",
                link: "https://www.facebook.com/comultrasan/"
            },
            {
                title: "Instagram",
                link: "https://www.instagram.com/financiera.comultrasan/"
            },
            {
                title: "X",
                link: "https://twitter.com/FComultrasan"
            }

        ],
        creditPrograms: [
            {
                title: "Crédito pregrado",
                link: "https://www.financieracomultrasan.com.co/es/personas/productos-y-servicios/para-crecer-credito/credito-educativo/credito-de-pregrado",
                benefits: [
                    "Atención personalizada.",
                    "Financiamos hasta el 100% del valor de la matrícula.",
                    "Crédito fácil y con ágil aprobación",
                    "El estudiante podrá empezar a adquirir experiencia crediticia a partir de los 18 años de edad, pues éste será el deudor o titular del crédito.",
                    "Si el estudiante es menor de edad, cualquier tercera persona podrá solicitar el crédito.",
                    "Podrás disfrutar del plan de beneficios que tenemos disponible para todos nuestros asociados.",
                    "Subsidiamos la vinculación a la Cooperativa.",
                    "Realizamos el desembolso directamente a la Entidad Educativa.",
                    "Contamos con convenios en algunas entidades que te ofrecerán descuentos especiales.",
                    "Ahorras tiempo, pues no necesariamente debes dirigirte a nuestros puntos de pago en agencias, manejamos diferentes modalidades como Agencia Virtual, puntos Efecty, Débito Automático desde la cuenta de ahorro Financiera Comultrasan.",
                    "Podrás realizar abonos extraordinarios al crédito sin ningún tipo de sanción."
                ],
                interestRate: "Desde 1.65% M.V. equivalente a 21.70%",
                interestType: "E.A.",
                requiremets: [
                    {
                        requirement: [
                            "Diligenciamiento de la solicitud de crédito.",
                            "Ser mayor de 18 años de edad.",
                            "Fotocopia ampliada al 150% del documento de identidad (titular y estudiante).",
                            "Liquidación y/o certificación de matrícula original.",
                            "Para montos superiores a 10 millones de pesos, es necesario adjuntar documentación que demuestre ingresos dependiendo del tipo de actividad económica que realice."

                        ]
                    },
                ],
            },
            {
                title: "Crédito postgrado",
                link: "https://www.financieracomultrasan.com.co/es/personas/productos-y-servicios/para-crecer-credito/credito-educativo/credito-de-estudio-posgrado",
                benefits: [
                    "Atención personalizada.",
                    "Financiamos hasta el 100% del valor de la matrícula.",
                    "Crédito fácil y con ágil aprobación",
                    "El estudiante podrá empezar a adquirir experiencia crediticia a partir de los 18 años de edad, pues éste será el deudor o titular del crédito.",
                    "Si el estudiante es menor de edad, cualquier tercera persona podrá solicitar el crédito.",
                    "Podrás disfrutar del plan de beneficios que tenemos disponible para todos nuestros asociados.",
                    "Subsidiamos la vinculación a la Cooperativa.",
                    "Realizamos el desembolso directamente a la Entidad Educativa.",
                    "Contamos con convenios en algunas entidades que te ofrecerán descuentos especiales.",
                    "Ahorras tiempo, pues no necesariamente debes dirigirte a nuestros puntos de pago en agencias, manejamos diferentes modalidades como Agencia Virtual, puntos Efecty, Débito Automático desde la cuenta de ahorro Financiera Comultrasan.",
                    "Podrás realizar abonos extraordinarios al crédito sin ningún tipo de sanción."
                ],
                interestRate: "Desde 1.65% M.V. equivalente a 21.70%",
                interestType: "E.A.",
                requiremets: [
                    {
                        requirement: [
                            "Diligenciamiento de la solicitud de crédito.",
                            "Ser mayor de 18 años de edad.",
                            "Fotocopia ampliada al 150% del documento de identidad (titular y estudiante).",
                            "Liquidación y/o certificación de matrícula original.",
                            "Para montos superiores a 10 millones de pesos, es necesario adjuntar documentación que demuestre ingresos dependiendo del tipo de actividad económica que realice."

                        ]
                    },
                ],
            },
            {
                title: "Crédito de cursos y educación no formal",
                link: "https://www.financieracomultrasan.com.co/es/personas/productos-y-servicios/para-crecer-credito/credito-educativo/credito-de-cursos-y-educacion",
                benefits: [
                    "Atención personalizada.",
                    "Financiamos hasta el 100% del valor de la matrícula.",
                    "Crédito fácil y con ágil aprobación",
                    "El estudiante podrá empezar a adquirir experiencia crediticia a partir de los 18 años de edad, pues éste será el deudor o titular del crédito.",
                    "Si el estudiante es menor de edad, cualquier tercera persona podrá solicitar el crédito.",
                    "Podrás disfrutar del plan de beneficios que tenemos disponible para todos nuestros asociados.",
                    "Subsidiamos la vinculación a la Cooperativa.",
                    "Realizamos el desembolso directamente a la Entidad Educativa.",
                    "Contamos con convenios en algunas entidades que te ofrecerán descuentos especiales.",
                    "Ahorras tiempo, pues no necesariamente debes dirigirte a nuestros puntos de pago en agencias, manejamos diferentes modalidades como Agencia Virtual, puntos Efecty, Débito Automático desde la cuenta de ahorro Financiera Comultrasan.",
                    "Podrás realizar abonos extraordinarios al crédito sin ningún tipo de sanción."
                ],
                interestRate: "Desde 1.65% M.V. equivalente a 21.70%",
                interestType: "E.A.",
                requiremets: [
                    {
                        requirement: [
                            "Diligenciamiento de la solicitud de crédito.",
                            "Ser mayor de 18 años de edad.",
                            "Fotocopia ampliada al 150% del documento de identidad (titular y estudiante).",
                            "Liquidación y/o certificación de matrícula original.",
                            "Para montos superiores a 10 millones de pesos, es necesario adjuntar documentación que demuestre ingresos dependiendo del tipo de actividad económica que realice."

                        ]
                    },
                ],
            },

        ],
        instituteNote: "En caso de requerir créditos mayores a $10 millones deberás realizar tu solicitud en las oficinas de Financiera Comultrasan o través de nuestro Contact Center: 018000938088. Monto: según tu necesidad de endeudamiento, garantía, plazo escogido y capacidad de pago."
    },
    {
        institute: "Fincomercio",
        link: "https://www.fincomercio.com/credito-educativo/",
        image: "../../assets/svg/fincomercio.svg",
        principalHeadquarter: {
            city: "Bogotá D.C.",
            region: "Colombia",
            address: "Cl. 80 # 28ª – 5, barrio Santa Sofía "
        },
        callCenterHours: {
            startDay: "Lunes",
            endDay: "viernes",
            schedule: "8:00 a.m. a 6:00 p.m.",
            weekendDay: "Sábado",
            weekendStartHour: "8:00 a.m.",
            weekendEndHour: "1:00 p.m."

        },
        numberChannels: [
            {
                city: "Bogotá D.C.",
                diallingCode: "(+57)",
                phoneNumber: "601 3078330"
            },
            {
                city: "Línea nacional (gratuita)",
                diallingCode: "",
                phoneNumber: "01 8000 111 876"
            }
        ],
        socialMedia: [
            {
                title: "Facebook",
                link: "https://www.facebook.com/Coop.Fincomercio/"
            },
            {
                title: "Instagram",
                link: "https://www.instagram.com/fincomercio_cooperativa_"
            },
            {
                title: "X",
                link: "https://twitter.com/Fincomercio_"
            }

        ],
        creditPrograms: [
            {
                title: "Crédito pregrado",
                link: "https://www.fincomercio.com/credito-educativo/",
                benefits: [
                    "El monto a financiar va desde $300.000 hasta el 100% del valor de tu matrícula.",
                    "Plazo de 3 a 8 meses.",
                    "¡Podrás ahorrar para tu futuro e iniciar tu historial crediticio que te abrirá muchas puertas!",
                    "Con tu crédito puedes solicitar tu subsidio educativo.",
                    "Si renuevas tu crédito y tienes un buen comportamiento de pago, te daremos una tasa preferencial.",
                    "Disfruta de nuestro portafolio de productos y servicios (Ahorro, inversiones CDAT, Seguro de Vida, Seguro de Vehículo, Seguro de Hogar y otras líneas de crédito), así como beneficios (convenios y subsidios) de Fincomercio solo para ti.",
                    "Financiamos hasta del 100% del valor de tu matrícula (según capacidad de pago)",
                    "No importa donde estudies: Universidad, Institución Universitaria, Tecnológica o Técnica, Fincoeducar financia tu semestre a corto o largo plazo.",
                    "Encuentra aquí las instituciones con las que tenemos convenio, podrás tener una tasa de interés preferencial a nivel nacional: Instituciones en Bogotá e Instituciones en otras ciudades para que estudies lo que quieras",
                    "Dependiendo de tu edad, puedes tener dos tipos de codeudor: Si eres menor de edad, es indispensable que tengas un codeudor de primer grado de consanguinidad. Si tienes entre 18 años hasta 21 Años es indispensable que tengas un codeudor de primer grado de consanguinidad o de afinidad.",
                    "Pregrado especial: Financiación exclusiva para programas desde $10.000.000 millones hasta el 100% del valor del programa; Plazo de Financiación: mínimo 3 máximo 18 meses ",
                    "Tiempo de respuesta: 1 Hora"

                ],
                interestRate: "Desde 1.65% M.V. equivalente a 21.70%",
                interestType: "E.A.",
                requiremets: [
                    {
                        type: "Empleado",
                        requirement: [
                            "Fotocopia de la CC y recibo de matrícula expedido por la Institución con fecha vigente.",
                            "Soporte de ingresos para solicitud por primera vez con Fincomercio.",
                            "Es importante que tengas tu cédula original en un archivo PDF.",
                            "Alista tu orden de matrícula original y la fotocopia actualizada.",
                            "Lleva tus últimos desprendibles de nómina de los últimos tres meses y si pagan quincenal, desprendible de los últimos 6 meses.",
                            "Solicita tu certificación laboral.",
                            "Certificación de ingresos y retenciones."

                        ]
                    },
                    {
                        type: "Pensionado",
                        requirement: [
                            "Fotocopia de la CC y recibo de matrícula expedido por la Institución con fecha vigente.",
                            "Soporte de ingresos para solicitud por primera vez con Fincomercio.",
                            "Solicita tus desprendibles de pensión de los últimos 3 meses.",
                            "Resolución de la pensión"
                        ]
                    },
                    {
                        type: "Independiente",
                        requirement: [
                            "Fotocopia de la CC y recibo de matrícula expedido por la Institución con fecha vigente.",
                            "Soporte de ingresos para solicitud por primera vez con Fincomercio",
                            "Solicita tus extractos bancarios de los últimos 3 meses.",
                            "Asegúrate de llevar tu RUT o cámara de comercio.",
                            "Solicita tu certificación por un contador y tarjeta profesional."

                        ]
                    },
                ],
            },
            {
                title: "Crédito postgrado",
                link: "https://www.fincomercio.com/credito-educativo/",
                benefits: [
                    "El monto a financiar va desde $300.000 hasta el 100% del valor de tu matrícula.",
                    "Tenemos 2 líneas de financiación para ti: Corto plazo(3 a 18 meses) y largo plazo (19 a 60 meses)",
                    "¡Podrás ahorrar para tu futuro e iniciar tu historial crediticio que te abrirá muchas puertas!",
                    "Con tu crédito puedes solicitar tu subsidio educativo.",
                    "Si renuevas tu crédito y tienes un buen comportamiento de pago, te daremos una tasa preferencial.",
                    "Disfruta de nuestro portafolio de productos y servicios (Ahorro, inversiones CDAT, Seguro de Vida, Seguro de Vehículo, Seguro de Hogar y otras líneas de crédito), así como beneficios (convenios y subsidios) de Fincomercio solo para ti.",
                    "Financiamos hasta del 100% del valor de tu matrícula (según capacidad de pago)",
                    "No importa donde estudies: Universidad, Institución Universitaria, Tecnológica o Técnica, Fincoeducar financia tu semestre a corto o largo plazo.",
                    "Encuentra aquí las instituciones con las que tenemos convenio, podrás tener una tasa de interés preferencial a nivel nacional: Instituciones en Bogotá e Instituciones en otras ciudades para que estudies lo que quieras",
                    "Dependiendo de tu edad, puedes tener dos tipos de codeudor: Si eres menor de edad, es indispensable que tengas un codeudor de primer grado de consanguinidad. Si tienes entre 18 años hasta 21 Años es indispensable que tengas un codeudor de primer grado de consanguinidad o de afinidad.",
                ],
                interestRate: "Desde 1.65% M.V. equivalente a 21.70%",
                interestType: "E.A.",
                requiremets: [
                    {
                        type: "Empleado",
                        requirement: [
                            "Fotocopia de la CC y recibo de matrícula expedido por la Institución con fecha vigente.",
                            "Alista tu orden de matrícula original y la fotocopia actualizada.",
                            "Lleva tus últimos desprendibles de nómina.",
                            "Solicita tu certificación laboral.",
                            "Certificación de ingresos y retenciones.",
                            "Diligencia el formulario de solicitud de tu crédito con el asesor de tu institución educativa o en tu oficina Fincomercio más cercana."

                        ]
                    },
                    {
                        type: "Pensionado",
                        requirement: [
                            "Solicita tus desprendibles de pensión de los últimos 3 meses.",
                            "Lleva tus extractos bancarios.",
                            "Diligencia el formulario de solicitud de tu crédito con el asesor de tu institución educativa o en tu oficina Fincomercio más cercana."
                        ]
                    },
                    {
                        type: "Independiente",
                        requirement: [
                            "Solicita tus extractos bancarios de los últimos 3 meses.",
                            "Asegúrate de llevar tu RUT",
                            "Solicita tu certificación por un contador y tarjeta profesional.",
                            "Diligencia el formulario de solicitud de tu crédito con el asesor de tu institución educativa o en tu oficina Fincomercio más cercana."

                        ]
                    },
                ],
            },

        ],
    },
    {
        institute: "Comfama",
        link: "https://www.comfama.com/finanzas/creditos-con-proposito/educacion/credito-educativo/",
        image: "../../assets/svg/comfama.svg",
        principalHeadquarter: {
            city: "Medellín",
            region: "Antioquia",
            address: "Carrera 45 # 49A-16"
        },
        callCenterHours: {
            startDay: "Lunes",
            endDay: "viernes",
            schedule: "8:00 a.m. a 6:00 p.m.",
            weekendDay: "Sábado",
            weekendStartHour: "8:00 a.m.",
            weekendEndHour: "1:00 p.m."

        },
        numberChannels: [
            {
                city: "Valle de Aburrá y Oriente cercano",
                diallingCode: "(+57)",
                phoneNumber: "604 3607080"
            },
            {
                city: "Municipios",
                diallingCode: "",
                phoneNumber: "01 8000 415 455"
            }
        ],
        socialMedia: [
            {
                title: "Facebook",
                link: "https://www.facebook.com/comfama/"
            },
            {
                title: "Instagram",
                link: "https://www.instagram.com/comfama/"
            },
            {
                title: "X",
                link: "https://twitter.com/Comfama/"
            }

        ],
        creditPrograms: [
            {
                title: "Crédito pregrado",
                link: "https://www.fincomercio.com/credito-educativo/",
                benefits: [
                    "El monto a financiar va hasta $30.000.000.",
                    "Plazo hasta 60 meses.",
                    "Garantía: Comisión FGA equivalente al 7,73% Iva incluido. Pago único al inicio.",
                    "Manejo:Valor pleno $20.000, pero si eres afiliado categoría A y B no lo pagas, tienes un beneficio; categoría C $20.000 (Se cobra una sola vez).",
                    "Zona de cobertura:Aplica para todo Antioquia.",
                    "Antigüedad laboral: 6 meses.",
                    "Tasa de interés baja con subsidio para categorías de afiliación A y B.",
                    "Amplia cobertura: Permite cursar estudios desde preescolar hasta postgrado en diferentes instituciones.",
                    "Amplios plazos que te permiten acceder a una cuota muy baja."

                ],
                interestRate: "Desde 1.60%",
                interestType: "M.V.",
                requiremets: [
                    {
                        requirement: [
                            "Contar con mínimo 6 meses de antigüedad laboral.",
                            "Tener entre 18 y 70 años.",
                            "Consulta el estado de tu solicitud.",
                            "Alista tu orden de matrícula original y la fotocopia actualizada.",
                            "No tener créditos en mora con Comfama.",
                            "Ser afiliado.",
                            "En calidad de trabajadores. Para pensionados e independientes si aportan el 2%."

                        ]
                    }
                ],
            },
        ],
        instituteNote: "Tarifa A: 1.60% M.V.(Afiliados que devengan hasta 2 salarios mínimos legales) - Tarifa B: 1.65% M.V. (Afiliados que devengan entre 2 y 4 salarios mínimos legales) - Tarifa C: 1.70% M.V. (Afiliados que devengan más de 4 salarios mínimos legales)"
    },
    {
        institute: "Bancoomeva",
        link: "https://www.bancoomeva.com.co/",
        image: "../../assets/svg/bancoomeva.svg",
        principalHeadquarter: {
            city: "Cali",
            region: "Colombia",
            address: "Calle 13 #57-50, Piso 2"
        },
        callCenterHours: {
            startDay: "Lunes",
            endDay: "viernes",
            schedule: "8:00 a.m. a 6:00 p.m.",
            weekendDay: "Sábado",
            weekendStartHour: "8:00 a.m.",
            weekendEndHour: "1:00 p.m."

        },
        numberChannels: [
            {
                city: "Barranquilla",
                diallingCode: "(+57)",
                phoneNumber: "605 3858686"
            },
            {
                city: "Bogotá D.C.",
                diallingCode: "(+57)",
                phoneNumber: "601 7458686"
            },
            {
                city: "Cali",
                diallingCode: "(+57)",
                phoneNumber: "602 4864242"
            },
            {
                city: "Medellín",
                diallingCode: "(+57)",
                phoneNumber: "604 6046868"
            },
            {
                city: "Línea nacional (gratuita)",
                diallingCode: "",
                phoneNumber: "01 8000 941 318"
            }
        ],
        socialMedia: [
            {
                title: "Facebook",
                link: "https://www.facebook.com/GrupoCoomeva/"
            },
            {
                title: "Instagram",
                link: "https://www.instagram.com/grupo_coomeva/?hl=es-la"
            },
            {
                title: "X",
                link: "https://twitter.com/Grupo_Coomeva"
            }

        ],
        creditPrograms: [
            {
                title: "Educar Tradicional",
                link: "https://www.bancoomeva.com.co/publicaciones/163542/educar-tradicional/",
                benefits: [
                    "Crédito destinado a la financiación total o parcial de educación básica primaria y secundaria, técnica, tecnologías, pregrado, posgrado, Educación Continua, idiomas y cursos cortos para el beneficiario, su cónyuge y familiares (hijos, padres, hermanos, nietos, y sobrinos).",
                    "El monto máximo a prestar es de $120.000.000.",
                    "El plazo de financiación es hasta 84 meses.",
                    "Período de gracia a capital los 6 primeros meses después del desembolso.",
                    "La tasa del crédito es fija.",
                    "Garantía exigida: Pagaré (Sujeto a política actual del Banco.)",
                    "El costo de la consulta en Centrales de Riesgo lo asume el aspirante al crédito.",
                    "Este crédito genera cobro del GMF.",
                    "Para educación en el exterior: a) Hasta el 30% del valor del cupo será para cubrir gastos relacionados con la educación, para pago de tiquetes y manutención. b)Hasta el 70% del valor del cupo se destinará para el pago de matrículas. c)En el momento del desembolso se solicitará el recibo de matrícula vigente. d)El desembolso para gastos diferentes a matrícula se podrá realizar en cheque o a cuenta."

                ],
                interestRate: "desde 1.76% equivalente a 23.29%",
                interestType: "E.A.",
                requiremets: [
                    {
                        type: "Empleado",
                        requirement: [
                            "Carta laboral no mayor a 30 días que especifique cargo, antigüedad, remuneración y tipo de contrato.",
                            "Fotocopia de los desprendibles de pago:",
                            "Salario mensual: Los dos (2) últimos comprobantes de pago",
                            "Salario quincenal: Los cuatro (4) últimos comprobantes de pago",
                            "Salario semanal: Los ocho (8) últimos comprobantes de pago",
                            "Fotocopia de la cédula de ciudadanía o extranjería ampliada al 150% o contraseña de la cédula de ciudadanía, siempre y cuando este documento se encuentre certificado por la Registraduría Nacional.",
                            "Si manifiestas tener otros ingresos, anexar los documentos que sustenten dichos valores."
                        ]
                    },
                    {
                        type: "Independiente",
                        requirement: [
                            "Registro de Cámara de Comercio de la empresa, cuando aplique, expedido con anterioridad no superior a 30 días.",
                            "Fotocopia de la declaración de renta o última del año fiscal.",
                            "Fotocopia de los extractos bancarios de los últimos tres meses, personal y/o de la empresa.",
                            "Balance general y estado de resultados del cierre fiscal del año anterior con sus respectivas notas, si aplica."
                        ]
                    },
                    {
                        type: "Pensionado",
                        requirement: [
                            "Fotocopia de la cédula de ciudadanía o extranjería ampliada al 150% o contraseña de la cédula de ciudadanía, siempre y cuando este documento se encuentre certificado por la Registraduría Nacional.",
                            "Fotocopia de los dos últimos desprendibles de pago.",
                            "Extractos Bancarios de los dos últimos meses, de la cuenta donde se consigna la pensión."
                        ]
                    }
                ],
            },
            {
                title: "Educar CEPOSDE",
                link: "https://www.bancoomeva.com.co/publicaciones/163549/credito-educar-ceposde/",
                benefits: [
                    "Producto ofrecido para los asociados a la Cooperativa Coomeva.",
                    "Es un crédito en donde el beneficiario podrá destinar los recursos a la educación y sostenimiento en postgrados con dedicación exclusiva. Los recursos producto del crédito CEPOSDE se destinan primordialmente al cubrimiento de las obligaciones adquiridas con la organización.",
                    "El monto máximo es de 72 SMMLV.",
                    "El plazo es hasta 60 meses.",
                    "Las garantías exigidas para CEPOSDE son de carácter personal: Dos codeudores solventes o, en su defecto, una garantía idónea.",
                    "La tasa del crédito es fija.",
                    "La cuota es fija con abono a capital e intereses durante todo el tiempo del crédito.",
                    "Requiere abrir una Cuenta de Ahorros con Bancoomeva y dar autorización permanente para débito automático de sus obligaciones mensuales con la organización.",
                    "El costo de la consulta en Centrales de Riesgo lo asume el aspirante al crédito.",
                    "Genera extracto mensual sin costo.",
                    "Puede hacer abonos extra o cancelarlo antes de la fecha de vencimiento final, sin costo de penalización.",
                    "Consulta las tasas y tarifas en nuestra zona inferior."

                ],
                interestRate: "Desde 1.76% equivalente a 23.29%",
                interestType: "E.A.",
                requiremets: [
                    {
                        type: "Empleado",
                        requirement: [
                            "Constancia de matrícula en la institución.",
                            "Ser asociado a Coomeva Cooperativa.",
                            "Anexar el certificado de estudio y residencia expedido por la Universidad respectiva, donde conste la dedicación exclusiva.",
                            "Carta laboral no mayor a 30 días que especifique cargo, antigüedad, remuneración y tipo de contrato.",
                            "Fotocopia de los desprendibles de pago:",
                            "Salario mensual: Los dos (2) últimos comprobantes de pago",
                            "Salario quincenal: Los cuatro (4) últimos comprobantes de pago",
                            "Salario semanal: Los ocho (8) últimos comprobantes de pago",
                            "Fotocopia de la cédula de ciudadanía o extranjería ampliada al 150% o contraseña de la cédula de ciudadanía, siempre y cuando este documento se encuentre certificado por la Registraduría Nacional.",
                            "Si manifiestas tener otros ingresos, anexar los documentos que sustenten dichos valores."
                        ]
                    },
                    {
                        type: "Independiente",
                        requirement: [
                            "Constancia de matrícula en la institución.",
                            "Ser asociado a Coomeva Cooperativa.",
                            "Anexar el certificado de estudio y residencia expedido por la Universidad respectiva, donde conste la dedicación exclusiva.",
                            "Registro de Cámara de Comercio de la empresa, cuando aplique, expedido con anterioridad no superior a 30 días.",
                            "Fotocopia de la declaración de renta o última del año fiscal.",
                            "Fotocopia de los extractos bancarios de los últimos tres meses, personal y/o de la empresa.",
                            "Balance general y estado de resultados del cierre fiscal del año anterior con sus respectivas notas, si aplica."
                        ]
                    },
                    {
                        type: "Pensionado",
                        requirement: [
                            "Constancia de matrícula en la institución.",
                            "Ser asociado a Coomeva Cooperativa.",
                            "Anexar el certificado de estudio y residencia expedido por la Universidad respectiva, donde conste la dedicación exclusiva.",
                            "Fotocopia de la cédula de ciudadanía o extranjería ampliada al 150% o contraseña de la cédula de ciudadanía, siempre y cuando este documento se encuentre certificado por la Registraduría Nacional.",
                            "Fotocopia de los dos últimos desprendibles de pago.",
                            "Extractos Bancarios de los dos últimos meses, de la cuenta donde se consigna la pensión."
                        ]
                    }
                ],
            },
            {
                title: "Educar Rotativo",
                link: "https://www.bancoomeva.com.co/publicaciones/163543/educar-rotativo/",
                benefits: [
                    "Cupo de Crédito destinado a la financiación total o parcial de educación básica primaria y secundaria, técnica, tecnologías, pregrado, posgrado, Educación Continua, idiomas y cursos cortos para el beneficiario, su cónyuge y familiares (hijos, padres, hermanos, nietos y sobrinos).",
                    "El monto máximo a prestar es de $120.000.000.",
                    "El plazo de financiación es hasta 36 meses.",
                    "Plazo para diferir el cupo en cada utilización es mínimo 1 mes y máximo 36 meses.",
                    "La tasa del crédito es fija.",
                    "Vigencia del cupo de Crédito: 8 años, donde lo podrás usar cada que requieras realizar un pago educativo, sin volver a hacer proceso de otorgamiento.",
                    "Sólo podrás realizar desembolsos en la Oficina Física, es decir, no puedes realizar utilizaciones por medio de los canales electrónicos.",
                    "Garantía exigida: Pagaré (Sujeto a política actual del Banco.)",
                    "El costo de la consulta en Centrales de Riesgo lo asume el aspirante al crédito.",
                    "Este crédito genera cobro del GMF."

                ],
                interestRate: "Desde 1.76% equivalente a 23.29%",
                interestType: "E.A.",
                requiremets: [
                    {
                        type: "Empleado",
                        requirement: [
                            "Carta laboral no mayor a 30 días que especifique cargo, antigüedad, remuneración y tipo de contrato.",
                            "Fotocopia de los desprendibles de pago:",
                            "Salario mensual: Los dos (2) últimos comprobantes de pago",
                            "Salario quincenal: Los cuatro (4) últimos comprobantes de pago",
                            "Salario semanal: Los ocho (8) últimos comprobantes de pago",
                            "Fotocopia de la cédula de ciudadanía o extranjería ampliada al 150% o contraseña de la cédula de ciudadanía, siempre y cuando este documento se encuentre certificado por la Registraduría Nacional.",
                            "Si manifiestas tener otros ingresos, anexar los documentos que sustenten dichos valores."
                        ]
                    },
                    {
                        type: "Independiente",
                        requirement: [
                            "Registro de Cámara de Comercio de la empresa, cuando aplique, expedido con anterioridad no superior a 30 días.",
                            "Fotocopia de la declaración de renta o última del año fiscal.",
                            "Fotocopia de los extractos bancarios de los últimos tres meses, personal y/o de la empresa.",
                            "Balance general y estado de resultados del cierre fiscal del año anterior con sus respectivas notas, si aplica."
                        ]
                    },
                    {
                        type: "Pensionado",
                        requirement: [
                            "Fotocopia de la cédula de ciudadanía o extranjería ampliada al 150% o contraseña de la cédula de ciudadanía, siempre y cuando este documento se encuentre certificado por la Registraduría Nacional.",
                            "Fotocopia de los dos últimos desprendibles de pago.",
                            "Extractos Bancarios de los dos últimos meses, de la cuenta donde se consigna la pensión."
                        ]
                    }
                ],
            },
        ],
        instituteNote: "Se solicitará documentación adicional de acuerdo a su actividad económica."
    },
    {
        institute: "Compensar",
        link: "https://corporativo.compensar.com/credito/lineas-de-credito/credito-educacion",
        image: "../../assets/svg/compensar.svg",
        principalHeadquarter: {
            city: "Bogotá D.C.",
            region: "Colombia",
            address: "Ak 68 #49a-31"
        },
        callCenterHours: {
            startDay: "Lunes",
            endDay: "viernes",
            schedule: "8:00 a.m. a 6:00 p.m.",
            weekendDay: "Sábado",
            weekendStartHour: "8:00 a.m.",
            weekendEndHour: "1:00 p.m."

        },
        numberChannels: [
            {
                city: "Bogotá D.C.",
                diallingCode: "(+57)",
                phoneNumber: "601 3077001"
            },
        ],
        socialMedia: [
            {
                title: "Facebook",
                link: "https://www.facebook.com/Compensarinfo"
            },
            {
                title: "Instagram",
                link: "https://www.instagram.com/compensar_info"
            },
            {
                title: "X",
                link: "https://twitter.com/Compensar_info"
            }

        ],
        creditPrograms: [
            {
                title: "Crédito educativo",
                link: "https://corporativo.compensar.com/credito/lineas-de-credito/credito-educacion",
                benefits: [
                    "Descuento por nómina.",
                    "Estudio de crédito sin costo.",
                    "Tasas de interés competitivas.",
                ],
                interestRate: "De acuerdo al perfil crediticio",
                interestType: "E.A.",
                requiremets: [
                    {
                        requirement: [
                            "Ser trabajador dependiente y afiliado principal (no beneficiario) a la Caja de Compensación Familiar Compensar, y tu afiliación debe estar activa.",
                            "Autorización de descuento por nómina firmada por ti y por la empresa donde laboras.",
                            "Tener mínimo 12 meses de antigüedad en la empresa donde laboras.",
                            "Ser mayor de 18 y menor de 65 años de edad.",
                            "La empresa y tú deben estar a paz y salvo por todo concepto con Compensar.",
                            "Si tienes cédula de extranjería, el plazo está sujeto al vencimiento de la cédula, la cual debe ser mínimo de un año.",
                            "Plazo máximo 36 meses. Para estudios de posgrado el plazo depende de la duración de cada ciclo.",
                            "Para todos nuestros créditos aplica el seguro de vida deudor, el cual será cobrado en el valor mensual de la cuota.",
                            "Desprendibles de nómina correspondientes a los dos (2) últimos meses (si el pago es quincenal, los cuatro (4) últimos desprendibles).",
                            "Si la empresa no emite desprendibles de nómina, se debe solicitar una certificación indicando cada uno de sus ingresos y egresos. Para empleadas del servicio doméstico, adicionalmente se deben adjuntar las planillas de pago de aportes de los dos últimos meses.",
                            "Certificación laboral donde se especifique cargo, sueldo, fecha de ingreso y tipo de contrato. La fecha de expedición no debe ser mayor a treinta (30) días.",
                            "Fotocopia de la orden de matrícula vigente indicando el valor del semestre y la fecha límite de pago.",
                            "Se puede solicitar el crédito después de hacer el pago del semestre presentando la solicitud dentro de los veinte (20) días siguientes a la fecha de pago."
                        ]
                    },
                ],
            },
        ],
        instituteNote: "* Las tasas varían de acuerdo con la categoría de afiliación y perfil crediticio de cada solicitante.Nota: El estudio y aprobación del crédito estarán sujetos al cumplimiento de las condiciones del producto. Ten en cuenta que Compensar te descuenta por adelantado los primeros intereses del crédito desde el día del desembolso hasta el día del pago de la primera cuota. Por lo tanto, recibirás un monto inferior al solicitado."
    },
    {
        institute: "Bancamía",
        link: "https://www.bancamia.com.co/productos/creditos_sector_agropecuario/credito-de-desarrollo-educacion/",
        image: "../../assets/svg/bancamia.svg",
        principalHeadquarter: {
            city: "Bogotá D.C.",
            region: "Colombia",
            address: "Carrera 9 66 25"
        },
        callCenterHours: {
            startDay: "Lunes",
            endDay: "viernes",
            schedule: "8:00 a.m. a 6:00 p.m.",
            weekendDay: "Sábado",
            weekendStartHour: "8:00 a.m.",
            weekendEndHour: "1:00 p.m."

        },
        numberChannels: [
            {
                city: "Bogotá D.C.",
                diallingCode: "(+57)",
                phoneNumber: "601 3077021"
            },
            {
                city: "Línea nacional (gratuita)",
                diallingCode: "",
                phoneNumber: "01 8000 126 100"
            }
        ],
        socialMedia: [
            {
                title: "Facebook",
                link: "https://www.facebook.com/bancamiaoficial/"
            },
            {
                title: "Instagram",
                link: "https://www.instagram.com/bancamiaoficial/"
            },

        ],
        creditPrograms: [
            {
                title: "Crédito de Desarrollo Educativo",
                link: "https://www.bancamia.com.co/productos/creditos_sector_agropecuario/credito-de-desarrollo-educacion/",
                benefits: [
                    "Puedes contar con crédito a tasas competitivas para atender necesidades de educación.",
                    "Tienes acceso a convenios educativos para nuestros clientes y su familia. Conoce en la página los convenios educativos.",
                    "Acceso a convenios educativos para nuestros clientes y sus familias.",
                    "A medida que genere mayor relación con Bancamía podrá tener beneficios de tasa y monto.",
                    "Destino: Financiación de la educación propia o de familiares hasta en segundo grado de consanguinidad, segundo de afinidad o único civil.",
                    "Clientes: Antiguo, urbanos y rural no agropecuario.",
                    "Monto: Min 70% de 1 SMLMV y Max 120 SMMLV (máximo 100% del valor de la matrícula).",
                    "Plazo mínimo: 3, 6 o 12 meses.",
                    "Tipo de amortización: Mensual.",
                    "Periodo de gracia: No aplica.",
                    "Condiciones especiales: Giro de cheque de gerencia o transferencia a cuenta de ahorros a nombre de la institución educativa según recibo de matrícula."
                ],
                interestRate: " 2.69% M.V. equivalente a 37.56%",
                interestType: "E.A.",
                requiremets: [
                    {
                        requirement: [
                            "Ser mayor de 18 años y menor de 74 años.",
                            "Ser propietario de microempresa y demostrar experiencia en el desarrollo de la actividad.",
                            "Acreditar posesión del negocio mínimo de 10 meses.",
                            "Tener un comportamiento de pago adecuado de acuerdo con lo reportado en las centrales de riesgo."
                        ]
                    },
                ],
            },
        ],
    },
    {
        institute: "Finky",
        link: "https://www.finky.la/solicita-tu-cupo",
        image: "../../assets/svg/finky.svg",
        principalHeadquarter: {
            city: "Bogotá D.C.",
            region: "Colombia",
            address: "Calle 163 A # 20 - 15 "
        },

        socialMedia: [
            {
                title: "Facebook",
                link: "https://www.facebook.com/finkylatam/"
            },
            {
                title: "Instagram",
                link: "https://www.instagram.com/finkylatam/"
            },
            {
                title: "X",
                link: "https://twitter.com/FinkyLatam"
            },

        ],
        creditPrograms: [
            {
                title: "Crédito educativo",
                link: "https://www.facebook.com/finkylatam/",
                benefits: [
                    "Sin codeudor.",
                    "100% en linea.",
                    "Políticas flexibles de aprobación.",
                    "La aprobación no depende únicamente de tu reporte en centrales de riesgo.",
                    "A Finky solo le pagas el cobro por servicios (usar simulador de cupo).",
                    "La renovación es garantizada con el pago total del cupo de crédito.",
                    "Te gradúas sin deudas."
                ],
                interestRate: "Pago por servicios",
                interestType: "N/A",
                requiremets: [
                    {
                        requirement: [
                            "Estar inscrito en una institución educativa aliada, tener fecha límite de pago y número de matrícula.",
                            "Tener a la mano computador o smartphone.",
                            "Regístrate aquí y llena el formulario en línea.",
                            "Pagar la primera cuota y ¡LISTO!"
                        ]
                    },
                ],
            },
        ],
    },
    {
        institute: "Financiera Progressa",
        link: "https://www.progressa.coop/portafolio/credito/credito-educativo/",
        image: "../../assets/svg/financieraProgressa.svg",
        principalHeadquarter: {
            city: "Bogotá D.C.",
            region: "Colombia",
            address: "Transversal 21 No. 98 – 71"
        },
        callCenterHours: {
            startDay: "Lunes",
            endDay: "viernes",
            schedule: "8:00 a.m. a 6:00 p.m.",
            weekendDay: "Sábado",
            weekendStartHour: "8:00 a.m.",
            weekendEndHour: "1:00 p.m."

        },
        numberChannels: [
            {
                city: "Bogotá D.C.",
                diallingCode: "(+57)",
                phoneNumber: "601 4866120"
            },
            {
                city: "Línea nacional (gratuita)",
                diallingCode: "",
                phoneNumber: "01 8000 413 583"
            }
        ],
        socialMedia: [
            {
                title: "Facebook",
                link: "https://www.facebook.com/FinancieraProgressa.coop"
            },
            {
                title: "Instagram",
                link: "https://www.instagram.com/financiera_progressa/"
            },
            {
                title: "X",
                link: "https://twitter.com/progressa_coop"
            },

        ],
        creditPrograms: [
            {
                title: "Crédito educativo",
                link: "https://www.progressa.coop/portafolio/credito/credito-educativo/",
                benefits: [
                    "Sin trámites complicados.",
                    "Plazos cómodos.",
                    "Tasas preferenciales.",
                    "Ágil respuesta.",
                    "Al realizar abonos extra, puedes elegir si se aplica a capital con disminución de plazo o con disminución de cuota.",
                    "Entre más tiempo lleves como asociado y mejor comportamiento de pago tengas, podrás recibir tasas más bajas y mejores plazos.",
                    "Respalda tu crédito con pólizas de La Equidad Seguros o Axa Colpatria.",
                    "Realiza tus pagos mediante nuestra zona transaccional o en nuestros canales de recaudo autorizados.",
                    "Cuando obtienes un crédito con Financiera Progressa, te conviertes en asociado y accedes a un plan de beneficios.",
                    "Para estudios de Pregrado:",
                    "Montos desde 1.5 SMMLV hasta de 39 SMMLV.",
                    "Plazos entre 4 a 12 meses, dependiendo de la duración del periodo académico.",
                    "Para estudios de Posgrado:",
                    "Montos desde 6 SMMLV hasta 100 SMMLV.",
                    "Plazos de financiación entre 6 y 60 meses.",
                    "Descuento por libranza: Descontamos el monto de la cuota directamente desde tu nómina. Aplica para personas que laboran en empresas que tengan convenio con Financiera Progressa.",
                    "Pago personal: El pago lo realiza el asociado directamente en nuestros canales de recaudo autorizados."

                ],
                interestRate: "Desde 2.37% equivalente a 32.39%",
                interestType: "E.A.",
                requiremets: [
                    {
                        requirement: [
                            "Documento de identificación original y fotocopia ampliada al 150%.",
                            "Solicitud de crédito diligenciada.",
                            "Recibo de pago de matrícula, con fecha mínima de pago para los siguientes tres días hábiles después de la radicación de la documentación completa.",
                            "Certificación laboral con expedición no mayor a 30 días.",
                            "Desprendible de nómina de los últimos 3 meses.",
                            "Extractos bancarios de los últimos tres meses (para asociados independientes).",
                            "Declaración de renta del último año o constancia de no declarante."
                        ]
                    },
                ],
            },
        ],
        instituteNote: "Condiciones sujetas a estudio de crédito, capacidad de pago y buen reporte en centrales de riesgo."
    },
    {
        institute: "U. Konrad Lorenz",
        link: "https://www.konradlorenz.edu.co/inversion/",
        image: "../../assets/images/logos/konrad-lorenz.png",
        principalHeadquarter: {
            city: "Bogotá D.C.",
            region: "Cundinamarca",
            address: "Carrera 9 Bis No. 62 - 43"
        },
        callCenterHours: {
            startDay: "Lunes",
            endDay: "viernes",
            schedule: "7:00 a.m. a 7:00 p.m.",
            // weekendDay: "Sábado",
            // weekendStartHour: "8:00 a.m.",
            // weekendEndHour: "4:00 p.m"
        },
        numberChannels: [
            {
                city: "",
                diallingCode: "(+57)",
                phoneNumber: "(601) 3472311 Opción No. 4"
            },
        ],
        socialMedia: [
            {
                title: "Whatsapp",
                link: "https://api.whatsapp.com/send?phone=573508592077&text=%C2%A1Hola%21%20Estoy%20interesado%20en%20obtener%20m%C3%A1s%20informaci%C3%B3n%20de%20m%C3%A9todos%20de%20financiancion%20en%20La%20Konrad%20%F0%9F%A4%93"
            }
        ],
        creditPrograms: [
            {
                title: "Línea de crédito de pregrado o posgrado / Becas",
                link: "https://www.konradlorenz.edu.co/inversion/",
                benefits: [
                    "Te ayudamos a continuar tu formación académica y a acceder a un pregado o posgrado en la Universidad Konrad Lorenz a través de diferentes tipos de apoyos financieros como becas, préstamos, auxilios y más. También contamos con convenios con instituciones como Icetex y Entidades Financieras."
                ],
                // requiremets: [
                // ],
                // interestRate: "Entre 13.12% al 23.30%",
                // interestType: "E.A."
            },
        ]
    },
    {
        institute: "El CESA",
        link: "https://www.cesa.edu.co/financiacion-becas/",
        image: "../../assets/images/logos/CESA.jpg",
        principalHeadquarter: {
            city: "Bogotá D.C.",
            region: "Cundinamarca",
            address: "Calle 35 No. 5-31 Piso 1, Casa Mejía"
        },
        callCenterHours: {
            startDay: "Lunes",
            endDay: "viernes",
            schedule: "7:00 a.m. a 7:00 p.m.",
            // weekendDay: "Sábado",
            // weekendStartHour: "8:00 a.m.",
            // weekendEndHour: "4:00 p.m"
        },
        numberChannels: [
            {
                city: "",
                diallingCode: "(+57)",
                phoneNumber: "(601) 339 5300, Ext. 2231"
            },
        ],
        socialMedia: [
            {
                title: "Facebook",
                link: "https://www.facebook.com/CESA.EDU.CO"
            },
            {
                title: "X",
                link: "https://x.com/CESA_edu"
            },
            {
                title: "Instagram",
                link: "https://www.instagram.com/cesa_edu/"
            },
        ],
        creditPrograms: [
            {
                title: "Becas y Financiación",
                link: "https://www.cesa.edu.co/financiacion-becas/",
                benefits: [
                    "Existen distintas líneas de apoyo para estudiantes como convenios y créditos directos en línea.",
                    "Contamos con un amplio programa de becas para apoyar a los estudiantes que lo necesiten.",
                ],
                // requiremets: [
                // ],
                // interestRate: "Entre 13.12% al 23.30%",
                // interestType: "E.A."
            },
        ]
    },
];